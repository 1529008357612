@import '~flatpickr/dist/flatpickr.css';
@import '~glightbox/dist/css/glightbox.css'; /* ==========================================================================
   Variables
   ========================================================================== */
/* Colors */
:root {
  --color-white: white;
  --color-black: #2d2d2d;
  --color-red: red;
  --color-green: #2ca536;
  --color-green2: #dcf0de;
  --color-gray-lighter: #f6f6f6;
  --color-gray-light: #efefef;
  --color-gray-light2: #e6e7e8;
  --color-gray-light3: #c9c9c9;
  --color-gray: #979797;
  --color-gray2: #646363;
  --color-gray3: #444444;
  --color-gray-dark: #3e3e3e;
  --color-yellow: #FFF5DF;
  --color-orange: #E8A200;
  --color-brown: #9A8000;
  --color-primary: var(--color-green);
  --color-secondary: var(--color-gray);
  --default-text-color: var(--color-gray2);
  --default-block-margin: 3rem;
  --default-block-padding: min(4vw, 3rem);
}

/* Screen adaptive config */
:root {
  --font-primary: "Roboto", sans-serif;
  --font-secondary: "Work Sans", sans-serif;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
}

/**
 * You can use font-smoothing best when using light text on a dark background
 * This only works on Mac OS X
 *
 * More info:
 * - http://www.intridea.com/blog/2014/5/8/better-font-smoothing-in-chrome-on-mac-os-x
 * - http://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 */
details {
  border: 1px solid var(--color-gray-light3);
  margin: 0 auto 5rem;
  padding: 1.35em 2em 0.35em 1em;
}
@media only screen and (min-width: 1081px) {
  details {
    max-width: 700px;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  details {
    max-width: 800px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  details {
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  details {
    max-width: 100%;
  }
}
details > summary {
  cursor: pointer;
  list-style: none;
  position: relative;
  color: var(--color-gray2);
}
details > summary:hover, details > summary:active {
  color: var(--color-green);
}
details > summary:hover::after, details > summary:active::after {
  color: inherit;
}
details > summary:focus {
  text-decoration: underline;
  outline: none;
}
details > summary > h2:not([class]), details > summary > h3:not([class]), details > summary > h4:not([class]) {
  color: inherit;
}
details > summary::-webkit-details-marker {
  display: none;
}
details > summary::-moz-list-bullet {
  list-style-type: none;
  display: block;
}
details > summary::after {
  content: "";
  display: block;
  background-image: url("../../../images/gray-arrow.svg");
  background-size: 8px 16px;
  width: 8px;
  height: 16px;
  color: var(--color-gray-light3);
  position: absolute;
  right: -1em;
  top: 0.5rem;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 0.2s transform ease-in-out;
}
@supports (mask-image: url("../../../images/gray-arrow.svg")) {
  details > summary::after {
    mask-image: url("../../../images/gray-arrow.svg");
    mask-size: 8px 16px;
    background-color: currentColor;
    background-image: none;
  }
}
details + details {
  border-top: 0;
  margin-top: -5rem;
}
details[open] {
  background-color: var(--color-gray-lighter);
}
details[open] summary {
  color: var(--color-green);
}
details[open] summary:focus {
  text-decoration: none;
}
details[open] summary::after {
  color: inherit;
  transform: rotate(-90deg);
}

hr {
  margin-top: 2rem;
  display: block;
  height: 1px;
  padding: 0;
  border: 0;
  border-top: 1px solid var(--color-gray-light2);
}
@media only screen and (min-width: 1081px) {
  hr {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  hr {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  hr {
    margin: 0 0 1em 0;
  }
}
@media only screen and (max-width: 500px) {
  hr {
    margin: 0 0 1.5rem 0;
  }
}
article hr {
  margin-left: auto;
  margin-right: auto;
}

img {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 0.4rem;
  max-width: 100%;
  height: auto;
}

picture > img {
  margin-top: 0;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: underline;
}
a.-arrow:after {
  content: "";
  display: inline-block;
  background: url(../../../images/green-arrow.svg) no-repeat;
  background-size: cover;
  width: 7px;
  height: 12px;
  margin-left: 10px;
}

a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
  cursor: inherit;
}
a[href^=tel]:hover, a[href^=tel]:focus {
  text-decoration: inherit;
  color: inherit;
  cursor: inherit;
}

button.link {
  border: 0;
  color: var(--color-primary);
  text-decoration: none;
  background: none;
  padding: 0;
  display: inline;
  cursor: pointer;
  margin-bottom: 0.5em;
}
button.link:hover, button.link:active, button.link:focus {
  text-decoration: underline;
  outline: none;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0 0 0 1.5em;
}
ul li, ol li {
  margin: 0;
  padding: 0;
}

ul.list--downloads, ul.-ticks, ul.-white, ul:not([class]), ol:not([class]) {
  overflow: hidden;
}
@media only screen and (min-width: 1081px) {
  ul.list--downloads, ul.-ticks, ul.-white, ul:not([class]), ol:not([class]) {
    margin: 0 0 1em 0;
    max-width: 700px;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  ul.list--downloads, ul.-ticks, ul.-white, ul:not([class]), ol:not([class]) {
    margin: 0 0 1em 0;
    max-width: 800px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  ul.list--downloads, ul.-ticks, ul.-white, ul:not([class]), ol:not([class]) {
    margin: 0 0 1em 0;
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  ul.list--downloads, ul.-ticks, ul.-white, ul:not([class]), ol:not([class]) {
    margin: 0 0 1.5rem 0;
    max-width: 100%;
  }
}
article ul.list--downloads, article ul.-ticks, article ul.-white, article ul:not([class]), article ol:not([class]) {
  margin-left: auto;
  margin-right: auto;
}
ul.list--downloads li, ul.-ticks li, ul.-white li, ul:not([class]) li, ol:not([class]) li {
  display: list-item;
  margin-bottom: 0.5em;
}

ul.-ticks li:before, ul.-white li:before, ul:not([class]) li:before {
  transition: all 0.1s ease-in-out;
}
ul.-ticks li:hover:before, ul.-white li:hover:before, ul:not([class]) li:hover:before {
  transform: scale(1.2) rotate(5deg);
}

ul.list--downloads li, ul.-ticks li, ul.-white li, ul:not([class]) li {
  position: relative;
}
ul.list--downloads li:before, ul.-ticks li:before, ul.-white li:before, ul:not([class]) li:before {
  content: "";
  position: absolute;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  left: -1em;
  color: var(--color-gray2);
}

ul:not([class]) li:before {
  background-image: url(../../../images/gray-arrow.svg);
  width: 7px;
  height: 12px;
  top: 5px;
}

ul.-white li {
  color: var(--color-white);
}
ul.-white li:before {
  background-image: url(../../../images/white-arrow.svg);
  width: 7px;
  height: 12px;
  top: 5px;
}

ul.-ticks {
  padding-left: 0;
}
ul.-ticks li {
  margin-bottom: 1em;
  padding-left: 2em;
}
ul.-ticks li::before {
  background-image: url(../../../images/tick.svg);
  width: 17px;
  height: 12px;
  top: 5px;
  left: 0;
}

ol:not([class]) {
  list-style-type: decimal;
}

::-moz-selection {
  background-color: var(--color-green);
  color: var(--color-white);
  text-shadow: none;
}

::selection {
  background-color: var(--color-green);
  color: var(--color-white);
  text-shadow: none;
}

:root {
  --table-border-color: transparent;
  --table-head-color: var(--default-text-color);
  --table-head-background-color: #e9e9e9;
  --table-row-color: var(--default-text-color);
  --table-row-background-color-even: #f9f9f9;
  --table-row-background-color-odd: var(--color-white);
}

table:not([class]) {
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
@media only screen and (min-width: 1081px) {
  table:not([class]) {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  table:not([class]) {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  table:not([class]) {
    margin: 0 0 1em 0;
  }
}
@media only screen and (max-width: 500px) {
  table:not([class]) {
    margin: 0 0 1.5rem 0;
  }
}
article table:not([class]) {
  margin-left: auto;
  margin-right: auto;
}
table:not([class]) tr:nth-child(even) {
  background-color: var(--table-row-background-color-even);
}
table:not([class]) tr:nth-child(odd) {
  background-color: var(--table-row-background-color-odd);
}
table:not([class]) th, table:not([class]) td {
  color: var(--table-row-color);
  padding: 0.5rem;
  border: 1px solid var(--table-border-color);
}
table:not([class]) th {
  color: var(--table-head-color);
  background: var(--table-head-background-color);
  vertical-align: middle;
  text-align: center;
}
table:not([class]) tbody th, table:not([class]) tbody td {
  vertical-align: top;
  text-align: left;
}
table:not([class]) ul, table:not([class]) ol {
  padding-left: 1rem;
}
@media only screen and (max-width: 500px) {
  table:not([class]) {
    display: block;
    position: relative;
    border-collapse: collapse;
    border-spacing: 0;
  }
  table:not([class]) th, table:not([class]) td {
    margin: 0;
    vertical-align: top;
  }
  table:not([class]) th:last-child, table:not([class]) td:last-child {
    border-bottom: 1px solid var(--table-border-color);
  }
  table:not([class]) th {
    text-align: left;
    display: block;
    border-right: 0;
  }
  table:not([class]) thead {
    display: block;
    float: left;
  }
  table:not([class]) thead tr {
    display: block;
    margin: 0;
    padding: 0;
  }
  table:not([class]) tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  table:not([class]) tbody tr {
    display: inline-block;
    vertical-align: top;
    border-left: 1px solid var(--table-border-color);
  }
  table:not([class]) td {
    display: block;
    min-height: 1.25em;
    text-align: left;
  }
  table:not([class]) th {
    border-bottom: 0;
    border-left: 0;
  }
  table:not([class]) td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  table:not([class]) {
    display: block;
    position: relative;
    border-collapse: collapse;
    border-spacing: 0;
  }
  table:not([class]) th, table:not([class]) td {
    margin: 0;
    vertical-align: top;
  }
  table:not([class]) th:last-child, table:not([class]) td:last-child {
    border-bottom: 1px solid var(--table-border-color);
  }
  table:not([class]) th {
    text-align: left;
    display: block;
    border-right: 0;
  }
  table:not([class]) thead {
    display: block;
    float: left;
  }
  table:not([class]) thead tr {
    display: block;
    margin: 0;
    padding: 0;
  }
  table:not([class]) tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  table:not([class]) tbody tr {
    display: inline-block;
    vertical-align: top;
    border-left: 1px solid var(--table-border-color);
  }
  table:not([class]) td {
    display: block;
    min-height: 1.25em;
    text-align: left;
  }
  table:not([class]) th {
    border-bottom: 0;
    border-left: 0;
  }
  table:not([class]) td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  table:not([class]) {
    display: block;
    position: relative;
    border-collapse: collapse;
    border-spacing: 0;
  }
  table:not([class]) th, table:not([class]) td {
    margin: 0;
    vertical-align: top;
  }
  table:not([class]) th:last-child, table:not([class]) td:last-child {
    border-bottom: 1px solid var(--table-border-color);
  }
  table:not([class]) th {
    text-align: left;
    display: block;
    border-right: 0;
  }
  table:not([class]) thead {
    display: block;
    float: left;
  }
  table:not([class]) thead tr {
    display: block;
    margin: 0;
    padding: 0;
  }
  table:not([class]) tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  table:not([class]) tbody tr {
    display: inline-block;
    vertical-align: top;
    border-left: 1px solid var(--table-border-color);
  }
  table:not([class]) td {
    display: block;
    min-height: 1.25em;
    text-align: left;
  }
  table:not([class]) th {
    border-bottom: 0;
    border-left: 0;
  }
  table:not([class]) td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
}

.table--transparent {
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
@media only screen and (min-width: 1081px) {
  .table--transparent {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .table--transparent {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .table--transparent {
    margin: 0 0 1em 0;
  }
}
@media only screen and (max-width: 500px) {
  .table--transparent {
    margin: 0 0 1.5rem 0;
  }
}
.table--transparent tr > *:first-child {
  padding-left: 0;
}
.table--transparent tr > *:last-child {
  padding-right: 0;
}
.table--transparent th, .table--transparent td {
  color: var(--table-row-color);
  padding: 0.5rem;
}
.table--transparent th.-align-right, .table--transparent td.-align-right {
  text-align: right;
}
.table--transparent th.-align-bottom, .table--transparent td.-align-bottom {
  vertical-align: bottom;
}
.table--transparent th > span, .table--transparent td > span {
  color: var(--color-gray2);
  font-style: italic;
  font-weight: normal;
}
.table--transparent th small, .table--transparent td small {
  font-size: 1.1rem;
  color: var(--color-gray);
  font-weight: var(--font-weight-regular);
}
.table--transparent th small.-move-up, .table--transparent td small.-move-up {
  position: relative;
  top: -15px;
}
.table--transparent th {
  color: var(--table-head-color);
  vertical-align: middle;
  text-align: center;
}
.table--transparent tbody th, .table--transparent tbody td, .table--transparent tfoot th, .table--transparent tfoot td {
  vertical-align: top;
  text-align: left;
}
.table--transparent tfoot {
  border-top: 1px solid var(--color-gray-light3);
}
.table--transparent tfoot th, .table--transparent tfoot td {
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
}
.table--transparent ul, .table--transparent ol {
  padding-left: 1rem;
}

table.-text-align-left th, table.-text-align-left td {
  text-align: left;
}

strong, b {
  font-weight: var(--font-weight-bold);
}

@media only screen and (min-width: 1081px) {
  p {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  p {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  p {
    margin: 0 0 1em 0;
  }
}
@media only screen and (max-width: 500px) {
  p {
    margin: 0 0 1.5rem 0;
  }
}
p.summary {
  font-size: 120%;
}
article p {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 1081px) {
  article p {
    max-width: 700px;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  article p {
    max-width: 800px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  article p {
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  article p {
    max-width: 100%;
  }
}

blockquote {
  font-style: italic;
  font-size: 120%;
}
@media only screen and (min-width: 1081px) {
  blockquote {
    margin: 0 0 1em 0;
    max-width: 700px;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  blockquote {
    margin: 0 0 1em 0;
    max-width: 800px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  blockquote {
    margin: 0 0 1em 0;
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  blockquote {
    margin: 0 0 1.5rem 0;
    max-width: 100%;
  }
}

article {
  overflow: hidden;
}

small {
  color: var(--color-gray);
}

.icon-paragraph {
  padding-left: 2.5rem;
  margin-bottom: 0.5em;
  position: relative;
}
.icon-paragraph::before {
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0.4rem;
  width: 1.5rem;
}
@supports (mask-image: url("../../../images/location.svg")) {
  .icon-paragraph::before {
    background-color: var(--color-primary);
    mask-repeat: no-repeat;
    mask-size: contain;
  }
}
.icon-paragraph.-location::before {
  width: 1.3rem;
  height: 1.6rem;
  background-image: url("../../../images/location.svg");
}
@supports (mask-image: url("../../../images/location.svg")) {
  .icon-paragraph.-location::before {
    background-image: none;
    mask-image: url("../../../images/location.svg");
  }
}
.icon-paragraph.-phone::before {
  background-image: url("../../../images/phone.svg");
}
@supports (mask-image: url("../../../images/phone.svg")) {
  .icon-paragraph.-phone::before {
    background-image: none;
    mask-image: url("../../../images/phone.svg");
  }
}
.icon-paragraph.-mail::before {
  width: 1.6rem;
  height: 1.3rem;
  top: 0.5rem;
  background-image: url("../../../images/mail.svg");
}
@supports (mask-image: url("../../../images/mail.svg")) {
  .icon-paragraph.-mail::before {
    background-image: none;
    mask-image: url("../../../images/mail.svg");
  }
}

/* Titles */
.steps__title::after, h4.-small-line::after, h5.-small-line::after, h6.-small-line::after, .-small-line.title--4::after, h3.-small-line::after, .-small-line.title--3::after, .-small-line.steps__title::after, .-small-line.title--full::after, .-small-line.title--3b::after, h2.-small-line::after, .-small-line.title--2b::after, .-small-line.title--2::after, .-small-line.title--1::after, .-small-line.title-bar__title::after, h1.-small-line::after {
  content: "";
  position: relative;
  display: block;
  max-width: 75px;
  height: 1px;
  margin: 0 auto;
  padding-block-end: 1rem;
  border-block-end: 1px solid var(--color-gray-light3);
}

h4, h5, h6, .title--4, h3, .title--3, .steps__title, .title--full, .title--3b, h2, .title--2b, .title--2, .title--1, .title-bar__title, h1 {
  display: block;
  font-family: var(--font-secondary);
  font-weight: var(--font-weight-medium);
  line-height: 1.25;
  color: var(--color-gray2);
}
@media only screen and (min-width: 1081px) {
  h4, h5, h6, .title--4, h3, .title--3, .steps__title, .title--full, .title--3b, h2, .title--2b, .title--2, .title--1, .title-bar__title, h1 {
    margin: 0 0 1em 0;
    max-width: 700px;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  h4, h5, h6, .title--4, h3, .title--3, .steps__title, .title--full, .title--3b, h2, .title--2b, .title--2, .title--1, .title-bar__title, h1 {
    margin: 0 0 1em 0;
    max-width: 800px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  h4, h5, h6, .title--4, h3, .title--3, .steps__title, .title--full, .title--3b, h2, .title--2b, .title--2, .title--1, .title-bar__title, h1 {
    margin: 0 0 1em 0;
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  h4, h5, h6, .title--4, h3, .title--3, .steps__title, .title--full, .title--3b, h2, .title--2b, .title--2, .title--1, .title-bar__title, h1 {
    margin: 0 0 1.5rem 0;
    max-width: 100%;
  }
}
article h4, article h5, article h6, article .title--4, article h3, article .title--3, article .steps__title, article .title--full, article .title--3b, article h2, article .title--2b, article .title--2, article .title--1, article .title-bar__title, article h1 {
  margin-inline: auto;
}
h4 a, h5 a, h6 a, .title--4 a, h3 a, .title--3 a, .steps__title a, .title--full a, .title--3b a, h2 a, .title--2b a, .title--2 a, .title--1 a, .title-bar__title a, h1 a {
  color: inherit;
  text-decoration: none;
}
h4.-line, h5.-line, h6.-line, .-line.title--4, h3.-line, .-line.title--3, .-line.steps__title, .-line.title--full, .-line.title--3b, h2.-line, .-line.title--2b, .-line.title--2, .-line.title--1, .-line.title-bar__title, h1.-line {
  padding-block-end: 1rem;
  border-block-end: 1px solid var(--color-gray-light3);
}
h4.-no-margin, h5.-no-margin, h6.-no-margin, .-no-margin.title--4, h3.-no-margin, .-no-margin.title--3, .-no-margin.steps__title, .-no-margin.title--full, .-no-margin.title--3b, h2.-no-margin, .-no-margin.title--2b, .-no-margin.title--2, .-no-margin.title--1, .-no-margin.title-bar__title, h1.-no-margin {
  margin-block-end: 0;
}
h4.-align-center, h5.-align-center, h6.-align-center, .-align-center.title--4, h3.-align-center, .-align-center.title--3, .-align-center.steps__title, .-align-center.title--full, .-align-center.title--3b, h2.-align-center, .-align-center.title--2b, .-align-center.title--2, .-align-center.title--1, .-align-center.title-bar__title, h1.-align-center {
  margin-inline: auto;
  text-align: center;
}

.title--1, .title-bar__title, h1 {
  font-size: 3.5rem;
  line-height: 1;
  text-transform: uppercase;
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .title--1, .title-bar__title, h1 {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 500px) {
  .title--1, .title-bar__title, h1 {
    font-size: 2.5rem;
  }
}

.title--2 {
  font-size: 2.6rem;
  font-weight: var(--font-weight-regular);
  text-transform: uppercase;
}
.title--2 b {
  display: block;
  margin-block-end: 3px;
  font-size: 4rem;
  font-weight: var(--font-weight-extra-bold);
  line-height: 1;
  color: var(--color-primary);
}
.title--2.-smaller {
  font-size: 2.2rem;
}

.title--2b {
  font-size: 2.6rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
}

.title--3, .steps__title, .title--full, .title--3b, h2 {
  font-size: 2rem;
}
.title--3.-arrow > a, .-arrow.steps__title > a, .-arrow.title--full > a, .-arrow.title--3b > a, h2.-arrow > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title--3.-arrow > a::after, .-arrow.steps__title > a::after, .-arrow.title--full > a::after, .-arrow.title--3b > a::after, h2.-arrow > a::after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 12px;
  margin-inline-start: 10px;
  background: url(../../../images/gray-arrow.svg) no-repeat;
  background-size: cover;
}

.title--3b {
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
}

.title--4, h3 {
  font-size: 1.8rem;
  color: var(--color-primary);
}

.title--meta {
  display: block;
  margin-block: 4px 2rem;
  font-size: 1.4rem;
  line-height: 1;
  color: var(--color-gray);
}

.title--full {
  font-weight: var(--font-weight-extra-bold);
  color: black;
  text-transform: uppercase;
}

/* ==========================================================================
   Global layout: header & footer
   ========================================================================== */
html, body {
  min-width: 320px;
  width: 100%;
  font-family: var(--font-primary);
}

html {
  color: var(--default-text-color);
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  min-width: 320px;
  word-wrap: break-word;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx) {
  body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
@media only screen and (min-width: 1081px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media only screen and (max-width: 500px) {
  body {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.booking__summary {
  background-color: var(--color-gray-lighter);
  padding: 2.6rem 2.2rem 2.3rem;
}
.booking__summary > .button {
  margin: 3rem 0 0;
}

.booking__title {
  color: var(--color-black);
  font-weight: var(--font-weight-bold);
}

.booking__info {
  display: flex;
  margin-bottom: 1rem;
}
.booking__info:last-of-type {
  margin-bottom: 0;
}
.booking__info b {
  color: var(--color-black);
  font-weight: var(--font-weight-bold);
}
.booking__info::before {
  content: "";
  display: block;
  margin: 0.2rem 1.7rem 0 0;
}
.booking__info.-location {
  margin-bottom: 1.7rem;
}
.booking__info.-location::before {
  background-image: url("../../images/location.svg");
  background-size: 1.1rem 1.4rem;
  width: 1.1rem;
  height: 1.4rem;
}
.booking__info.-date::before {
  background-image: url("../../images/calendar.svg");
  background-size: 1.2rem 1.4rem;
  width: 1.2rem;
  height: 1.4rem;
}
.booking__info.-time::before {
  margin-right: 1.5rem;
  background-image: url("../../images/time.svg");
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
}

.booking__message {
  max-width: 72rem;
  color: var(--color-gray);
  font-size: 1.8rem;
  line-height: 1.56;
}

.booking-steps {
  --step-circle-width: 12px;
  --setp-stripe-width: 23.2px;
  background-color: var(--color-gray-lighter);
  background-image: linear-gradient(90deg, var(--color-primary) 0%, var(--color-primary) var(--step-width), var(--step-width), transparent 100%);
  height: 12px;
  margin: 3em auto 3.8rem;
  mask-image: url("../../images/steps-meter.svg");
  text-indent: -999px;
  width: 223px;
}
.booking-steps.-step-1 {
  --step-width: var(--step-circle-width);
}
.booking-steps.-step-2 {
  --step-width: calc(2 * var(--step-circle-width) + var(--setp-stripe-width));
}
.booking-steps.-step-3 {
  --step-width: calc(3 * var(--step-circle-width) + 2 * var(--setp-stripe-width));
}
.booking-steps.-step-4 {
  --step-width: calc(4 * var(--step-circle-width) + 3 * var(--setp-stripe-width));
}
.booking-steps.-step-5 {
  --step-width: calc(5 * var(--step-circle-width) + 4 * var(--setp-stripe-width));
}
.booking-steps.-step-6 {
  --step-width: calc(6 * var(--step-circle-width) + 5 * var(--setp-stripe-width));
}
.booking-steps.-step-7 {
  --step-width: calc(7 * var(--step-circle-width) + 6 * var(--setp-stripe-width));
}

.box--category, .box--gray, .box--product, .box {
  margin-block-end: var(--default-block-margin);
  padding: var(--default-block-padding);
}
.box--category > *:last-child, .box--gray > *:last-child, .box--product > *:last-child, .box > *:last-child {
  margin-block-end: 0;
}
.box--category table, .box--gray table, .box--product table, .box table {
  width: 100%;
}
.box--category p, .box--gray p, .box--product p, .box p {
  margin-block-end: 1rem;
}
.-small-top-padding.box--category, .-small-top-padding.box--gray, .-small-top-padding.box--product, .-small-top-padding.box {
  padding-block-start: 1rem;
}

.box {
  padding-block: 0;
}

.box--gray, .box--product {
  color: var(--color-gray2);
  background-color: var(--color-gray-lighter);
}

.box--product {
  padding-inline: 5cqw;
  text-align: center;
}
.box--product .title--3b {
  text-align: start;
}
.-bestseller .box--product {
  border: 2px solid var(--color-black);
  border-top: 0;
}
.box--product__image {
  display: block;
}
.-bestseller .box--product__image {
  border: 2px solid var(--color-black);
  border-bottom: 0;
}

.box--category {
  padding-block-start: 0;
}
@media only screen and (max-width: 500px) {
  .box--category {
    margin-block-end: 3rem;
    padding: 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .box--category {
    margin-block-end: 3rem;
    padding: 0;
  }
}
.box--category ul {
  margin-block-end: 1rem;
}

.box--booking {
  max-width: 782px;
  margin: 0 auto;
  padding: 4rem min(4vw, 4rem);
  border: 1px solid var(--color-gray-light2);
}
.box--booking__separator {
  margin: 2em -4rem;
}

.box--advantages {
  order: 1;
  margin-block-start: 1rem;
}
@media only screen and (min-width: 850px) {
  .box--advantages {
    order: 0;
    margin-block-start: 0;
  }
  .box--advantages > picture {
    display: none;
  }
}
@media only screen and (min-width: 1080px) {
  .box--advantages ul {
    column-count: 2;
  }
  .box--advantages ul > li {
    margin-inline-end: 5rem;
  }
}

.breadcrumbs {
  text-align: center;
  margin-bottom: 1.7rem;
  padding: 0;
}

.breadcrumb {
  display: inline-block;
  color: var(--color-gray);
}
@media only screen and (max-width: 500px) {
  .breadcrumb {
    margin-bottom: 0.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .breadcrumb {
    margin-bottom: 0.5rem;
  }
}
.breadcrumb::before {
  content: "";
  display: inline-block;
  background-image: url("../../images/gray-arrow.svg");
  background-size: 0.8rem 1.2rem;
  width: 0.8rem;
  height: 1.2rem;
  margin: 0 0.8rem;
}
.breadcrumb.-active {
  color: var(--color-black);
}
.breadcrumb.-finished {
  color: var(--color-green);
}
.breadcrumb:first-of-type::before {
  display: none;
}

/*
 * Help us to create a better world and kill the old browsers (<= IE 7)
 */
.browse-happy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 1em;
}

/* Button */
.button--white, .button--black, .button--transparent, .button {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  padding: 0.5em 1.5em;
  min-height: 3.5em;
  vertical-align: middle;
  margin: 0 1em 1rem 0;
  border: 2px solid transparent;
  border-radius: 2em;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 1.5rem;
  line-height: 1.2;
  text-decoration: none;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
}
.button--white:hover, .button--black:hover, .button--transparent:hover, .button:hover, .button--white:focus, .button--black:focus, .button--transparent:focus, .button:focus {
  outline: none;
  text-decoration: none;
}
.-full-width.button--white, .-full-width.button--black, .-full-width.button--transparent, .-full-width.button {
  width: 100%;
}
.is-loading[disabled].button--white, .is-loading[disabled].button--black, .is-loading[disabled].button--transparent, .is-loading[disabled].button {
  position: relative;
  color: transparent;
}
.is-loading[disabled].button--white:hover, .is-loading[disabled].button--black:hover, .is-loading[disabled].button--transparent:hover, .is-loading[disabled].button:hover, .is-loading[disabled].button--white:focus, .is-loading[disabled].button--black:focus, .is-loading[disabled].button--transparent:focus, .is-loading[disabled].button:focus {
  color: transparent;
}
.is-loading[disabled].button--white:before, .is-loading[disabled].button--black:before, .is-loading[disabled].button--transparent:before, .is-loading[disabled].button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin: -20px -20px;
  background-color: var(--color-white);
  border-radius: 100%;
  animation: scaleout 1s infinite ease-in-out;
}
.-arrow.button--white, .-arrow.button--black, .-arrow.button--transparent, .-arrow.button {
  padding-inline-end: 1em;
}
.-arrow.button--white:after, .-arrow.button--black:after, .-arrow.button--transparent:after, .-arrow.button:after {
  content: "";
  display: inline-block;
  background: url("../../images/white-arrow.svg") no-repeat;
  background-size: cover;
  width: 7px;
  height: 12px;
  margin-left: 15px;
  position: relative;
  top: 1px;
}
@media only screen and (max-width: 378px) {
  .-arrow.button--white:after, .-arrow.button--black:after, .-arrow.button--transparent:after, .-arrow.button:after {
    display: none;
  }
}

.button:hover, .button:focus {
  opacity: 0.9;
}
.button:active {
  opacity: 0.95;
}

.button--transparent {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3);
}
.button--transparent:hover, .button--transparent:focus {
  border-color: rgba(255, 255, 255, 0.4);
}
.button--transparent:active {
  border-color: rgba(255, 255, 255, 0.5);
}

.button--black {
  background-color: var(--color-black);
}
.button--black:hover, .button--black:focus {
  background-color: rgba(0, 0, 0, 0.7);
}
.button--black:active {
  background-color: rgba(0, 0, 0, 0.5);
}

.button--white {
  background-color: white;
  color: var(--color-gray-dark);
  border-color: var(--color-gray-light2);
  font-weight: normal;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}
.button--white:hover, .button--white:focus {
  border-color: var(--color-gray-light3);
}
.button--white:active {
  border-color: var(--color-black);
}

@keyframes scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 20.2rem;
  position: relative;
  width: 100%;
  margin-bottom: 0.8rem;
  overflow: hidden;
}
.card > p {
  margin: 0 2rem 2rem;
  color: var(--color-white);
  font-family: var(--font-secondary);
  font-weight: var(--font-weight-semi-bold);
  font-size: 2rem;
  line-height: 1.25;
}
.card > img {
  display: block;
  height: 100%;
  margin: 0;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  transition: opacity 0.2s ease-in-out;
}
.card:hover, .card:focus, .card:active {
  text-decoration: none;
}
.card:hover > img, .card:focus > img, .card:active > img {
  opacity: 0.9;
}
@media only screen and (min-width: 1080px) {
  .card {
    height: 23.2rem;
    width: 41.3rem;
    margin-bottom: 2rem;
  }
}

.card__tag {
  color: var(--color-green);
  font-family: var(--font-secondary);
  font-weight: var(--font-weight-bold);
  font-size: 1.4rem;
  text-transform: uppercase;
  background-color: var(--color-white);
  padding: 0.5rem 0.8rem;
  margin: 0 0 0.5rem 2rem;
}

.contact-map {
  height: 50rem;
  max-height: 50vh;
  width: 100%;
}

:root {
  --cookie-consent-max-width: 42em;
  --cookie-consent-color: inherit;
  --cookie-consent-background-color: white;
  --cookie-consent-options-color: inherit;
  --cookie-consent-options-background-color: lightgray;
  --cookie-consent-iframe-template-background-color: lightgray;
  --cookie-consent-iframe-template-max-width: 100%;
}

.cookie-consent {
  position: fixed;
  z-index: var(--cookie-consent-z-index, 999);
  inset-block-end: var(--cookie-consent-bottom, 0);
  transform: var(--cookie-consent-transform, none);
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  max-width: var(--cookie-consent-max-width);
  max-height: 100%;
  margin-block: var(--cookie-consent-margin-block, auto 0);
  margin-inline: var(--cookie-consent-margin-inline, auto 0);
  padding: var(--cookie-consent-padding, 2em);
  color: var(--cookie-consent-color);
  background-color: var(--cookie-consent-background-color);
  border: 0;
}
.cookie-consent a:not([class]) {
  color: var(--cookie-consent-link-color, inherit);
}
.cookie-consent.-is-modal {
  --cookie-consent-margin-inline: auto;
}
@media (min-width: 45rem), (min-height: 31rem) {
  .cookie-consent.-is-modal {
    --cookie-consent-bottom: 50%;
    --cookie-consent-transform: translate3d(0, calc(50% + .5px), 0);
  }
}
@media (max-width: 45rem), (max-height: 31rem) {
  .cookie-consent:not(.-is-modal) {
    --cookie-consent-max-width: none;
  }
}

.cookie-consent__title {
  margin-block-start: 0;
  color: inherit;
}

.cookie-consent__options {
  margin: 0 0 2em;
  padding: 1em;
  color: var(--cookie-consent-options-color);
  background: var(--cookie-consent-options-background-color);
}

.cookie-consent__option-description {
  display: block;
  margin-block: 0;
  font-size: 90%;
}

.cookie-consent__buttons:not([hidden]) {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.cookie-consent__buttons:not([hidden]) .-general {
  margin-block-start: 1em;
}

template.-pending-consent {
  cursor: pointer;
  position: relative;
  display: block;
  aspect-ratio: var(--cookie-consent-cover-aspect-ratio, 16/9);
  max-width: var(--cookie-consent-iframe-template-max-width);
  min-height: 300px;
  margin-inline: auto;
  padding-block-end: var(--cookie-consent-iframe-template-bottom-whitespace, 4rem);
}
template.-pending-consent::before {
  content: attr(title);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 2rem 4rem;
  color: var(--cookie-consent-iframe-template-color, inherit);
  text-align: center;
  background-color: var(--cookie-consent-iframe-template-background-color);
  background-image: var(--cookie-consent-cover-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

:root {
  --cookie-consent-options-background-color: var(--color-gray-lighter);
}

.cookie-consent {
  border-block-start: 2px solid var(--color-gray-light2);
  border-inline-start: 2px solid var(--color-gray-light2);
}

.cookie-consent__options label {
  font-weight: bold;
}
.cookie-consent__options input[type=checkbox] {
  margin-inline-end: 0.5rem;
}

.cookie-consent__option-description {
  font-weight: normal;
}

#datepicker {
  display: none;
}

.flatpickr-calendar {
  max-width: 35.6rem !important;
  width: 100%;
  margin: 0 0 0 auto;
  background-color: var(--color-gray-light);
  padding: 3.5rem 2.4rem;
  font-family: var(--font-primary);
  font-size: 1.6rem !important;
  letter-spacing: 0.07rem;
}
@media only screen and (max-width: 500px) {
  .flatpickr-calendar {
    width: 100% !important;
    margin: 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .flatpickr-calendar {
    width: 100% !important;
    margin: 0;
  }
}

.flatpickr-innerContainer {
  overflow-x: auto;
}

.flatpickr-weekdaycontainer,
.dayContainer,
.flatpickr-days {
  max-width: 35.6rem !important;
  width: 100% !important;
}

.flatpickr-months {
  padding: 0 0 4.7rem;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  top: 3.5rem !important;
}
.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  vertical-align: top;
}
.flatpickr-prev-month:hover svg, .flatpickr-prev-month:focus svg,
.flatpickr-next-month:hover svg,
.flatpickr-next-month:focus svg {
  fill: var(--color-green) !important;
}

.flatpickr-prev-month {
  left: 2.4rem !important;
}

.flatpickr-next-month {
  right: 2.4rem !important;
}

.flatpickr-current-month {
  font-family: var(--font-secondary);
  font-size: 1.8rem;
}

.flatpickr-monthDropdown-months {
  -webkit-appearance: none !important;
}

.flatpickr-weekday {
  font-family: var(--font-primary);
  font-size: 1.6rem !important;
  letter-spacing: 0.07rem;
  max-width: 4.4rem !important;
}

.flatpickr-day {
  max-width: 4.4rem !important;
  height: 4.4rem;
  color: var(--color-black);
  line-height: 4.4rem !important;
  border: none;
  transition: all 0.3s ease-in-out;
}
.flatpickr-day.today {
  background-color: var(--color-white);
}
.flatpickr-day.selected {
  background-color: var(--color-green);
}

.flatpickr-day:hover, .flatpickr-day:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.selected:focus {
  background-color: var(--color-gray-light3);
  color: var(--color-black) !important;
}

.datepicker__timeslot__day {
  display: block;
  padding: 1.6rem;
  background-color: var(--color-black);
  color: var(--color-white);
  font-family: var(--font-secondary);
  font-weight: var(--font-weight-medium);
  font-size: 2rem;
}

.datepicker__timeslot__options {
  padding: 1.6rem;
  border: 0.1rem solid var(--color-gray-light);
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.downloads-list {
  column-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 21em));
  margin-bottom: 2em;
  padding-left: 0;
  row-gap: 1.4rem;
}
.downloads-list > li {
  display: block;
}
.downloads-list > li > a {
  align-items: center;
  background-color: var(--color-gray-light2);
  color: var(--color-black);
  display: flex;
  justify-items: start;
  padding: 1rem 5rem 1rem 1rem;
  position: relative;
  text-decoration: none;
}
.downloads-list > li > a:hover, .downloads-list > li > a:active, .downloads-list > li > a:focus {
  text-decoration: underline;
}
.downloads-list > li > a::before {
  background-image: url("../../images/contract.jpg");
  content: "";
  display: inline-block;
  height: 4.1rem;
  margin-right: 0.5em;
  width: 3.1rem;
}
.downloads-list > li > a::after {
  background-image: url("../../images/download.svg");
  content: "";
  display: inline-block;
  height: 1.8rem;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown > .form__label {
  display: none;
}
@media only screen and (max-width: 500px) {
  .dropdown > .form__label {
    display: block;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .dropdown > .form__label {
    display: block;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .dropdown > .form__label {
    display: block;
  }
}
.dropdown.-open .dropdown__options {
  display: block;
}
.dropdown.-open .dropdown__selected .dropdown__selected__info::after {
  transform: rotate(180deg);
}

.dropdown__selected {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.2rem 1.3rem 1.3rem;
}
@media only screen and (min-width: 1081px) {
  .dropdown__selected {
    min-width: 33rem;
  }
}
@media only screen and (max-width: 500px) {
  .dropdown__selected {
    background-color: var(--color-gray3);
  }
  .dropdown__selected .form__label {
    display: none;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .dropdown__selected {
    background-color: var(--color-gray3);
  }
  .dropdown__selected .form__label {
    display: none;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .dropdown__selected {
    background-color: var(--color-gray3);
  }
  .dropdown__selected .form__label {
    display: none;
  }
}
.dropdown__selected:hover, .dropdown__selected:focus {
  background-color: transparent;
}
@media only screen and (max-width: 500px) {
  .dropdown__selected:hover, .dropdown__selected:focus {
    background-color: var(--color-gray2);
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .dropdown__selected:hover, .dropdown__selected:focus {
    background-color: var(--color-gray2);
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .dropdown__selected:hover, .dropdown__selected:focus {
    background-color: var(--color-gray2);
  }
}
.dropdown__selected > img {
  width: 7.9rem;
  height: 7.9rem;
  margin: 0 1.4rem 0 0;
}
@media only screen and (max-width: 500px) {
  .dropdown__selected > img {
    width: 3.8rem;
    height: 3.8rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .dropdown__selected > img {
    width: 3.8rem;
    height: 3.8rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .dropdown__selected > img {
    width: 3.8rem;
    height: 3.8rem;
  }
}
.dropdown__selected > div {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  width: 100%;
}

.dropdown__selected__info {
  max-width: 25rem;
}
@media only screen and (max-width: 500px) {
  .dropdown__selected__info {
    position: relative;
    display: flex;
    align-items: center;
    max-width: none;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .dropdown__selected__info {
    position: relative;
    display: flex;
    align-items: center;
    max-width: none;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .dropdown__selected__info {
    position: relative;
    display: flex;
    align-items: center;
    max-width: none;
  }
}
.dropdown__selected__info::after {
  content: "";
  display: inline-block;
  margin-left: 0.8rem;
  background-image: url("../../images/select-arrow-white.svg");
  background-size: 1.4rem 0.8rem;
  width: 1.4rem;
  height: 0.8rem;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .dropdown__selected__info::after {
    position: absolute;
    right: 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .dropdown__selected__info::after {
    position: absolute;
    right: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .dropdown__selected__info::after {
    position: absolute;
    right: 0;
  }
}

.dropdown__options {
  display: none;
  width: 100%;
  position: absolute;
  top: 10.4rem;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  max-height: 50vh;
  overflow: scroll;
}
@media only screen and (max-width: 500px) {
  .dropdown__options {
    top: 8rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .dropdown__options {
    top: 8rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .dropdown__options {
    top: 8rem;
  }
}

.dropdown__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white);
  border: 0.1rem solid var(--color-gray-light3);
  border-top: none;
  padding: 0.8rem 2rem 0.8rem 1rem;
  color: var(--default-text-color);
  font-family: var(--font-primary);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .dropdown__option {
    margin: 0 1.8rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .dropdown__option {
    margin: 0 1.8rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .dropdown__option {
    margin: 0 1.8rem;
  }
}
.dropdown__option span {
  display: flex;
  align-items: center;
}
.dropdown__option img {
  width: 9.6rem;
  height: 8.6rem;
  margin: -0.8rem 1.2rem -0.8rem -1rem;
  object-fit: cover;
}
.dropdown__option:hover, .dropdown__option:focus {
  background-color: var(--color-gray-light3);
}
.dropdown__option.-active {
  background-color: var(--color-green);
  border: 0.1rem solid var(--color-green);
  color: var(--color-white);
}
.dropdown__option .product__image-promo {
  inset-inline: auto 0;
  font-size: 1.2rem;
  padding: 0.2rem 0.5rem;
}

.facilities {
  display: flex;
}
@media only screen and (max-width: 500px) {
  .facilities {
    display: block;
  }
}
.facilities > * {
  margin-bottom: 2rem;
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .facilities > * {
    width: calc(50% - 1rem);
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .facilities > * {
    width: calc(50% - 1rem);
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 1081px) {
  .facilities > * {
    width: calc(50% - 1rem);
    margin-right: 2rem;
  }
}
.facilities > *:nth-child(2n) {
  margin-right: 0;
}

.figure {
  width: 100%;
  margin: 0.4rem 0 1rem;
  position: relative;
}
.figure img {
  margin-top: 0;
  width: 100%;
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .-size-medium.figure {
    width: 50%;
    margin-left: 0;
  }
  .-size-small.figure {
    width: 30%;
    margin-left: 0;
  }
  .-align-left.figure {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .-align-right.figure {
    float: right;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1081px) {
  .-size-medium.figure {
    width: 50%;
    margin-left: 0;
  }
  .-size-small.figure {
    width: 30%;
    margin-left: 0;
  }
  .-align-left.figure {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .-align-right.figure {
    float: right;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 500px) {
  .figure {
    width: 100%;
    margin-left: 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .figure {
    width: 100%;
    margin-left: 0;
  }
}

.figure.-align-center {
  text-align: center;
  margin: 0 auto 1rem;
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .figure.-align-left {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .figure.-align-right {
    float: right;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1081px) {
  .figure.-align-left {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .figure.-align-right {
    float: right;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}

/* Is used by the webadmin */
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .figure--left a.img,
  .figure--left a.img--alt,
  .figure--left img {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1081px) {
  .figure--left a.img,
  .figure--left a.img--alt,
  .figure--left img {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .figure--right a.img,
  .figure--right a.img--alt,
  .figure--right img {
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1081px) {
  .figure--right a.img,
  .figure--right a.img--alt,
  .figure--right img {
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
  }
}

.figure--center {
  clear: both;
}
@media only screen and (min-width: 1081px) {
  .figure--center {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .figure--center {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .figure--center {
    margin: 0 0 1em 0;
  }
}
@media only screen and (max-width: 500px) {
  .figure--center {
    margin: 0 0 1.5rem 0;
  }
}
.figure--center a.img,
.figure--center a.img--alt,
.figure--center img {
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1081px) {
  figure {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  figure {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  figure {
    margin: 0 0 1em 0;
  }
}
@media only screen and (max-width: 500px) {
  figure {
    margin: 0 0 1.5rem 0;
  }
}
figure.center {
  text-align: center;
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  figure.left img {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  figure.right img {
    float: right;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1081px) {
  figure.left img {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  figure.right img {
    float: right;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
article figure {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 1081px) {
  article figure {
    max-width: 700px;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  article figure {
    max-width: 800px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  article figure {
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  article figure {
    max-width: 100%;
  }
}

.flash-container {
  opacity: 1;
  transition: opacity 0.8s;
}
.flash-container .-hide {
  opacity: 0;
}

.flash-message--warning, .flash-message--info, .flash-message--error, .flash-message--success {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  /*&:before {
      display: inline-block;
      position: absolute;
      left: 20px;
      width: 25px;
      text-align: center;
  }*/
}
.flash-message--warning > *:last-child, .flash-message--info > *:last-child, .flash-message--error > *:last-child, .flash-message--success > *:last-child {
  margin-bottom: 0;
}
.flash-message--warning p, .flash-message--info p, .flash-message--error p, .flash-message--success p {
  margin: 0;
  max-width: 100%;
  color: inherit;
  line-height: 1.25;
}

.flash-message--success {
  background: rgba(0, 128, 0, 0.001);
  color: green;
  /*&:before {
      @extend .icon--check;
  }*/
}

.flash-message--error {
  background: rgba(255, 0, 0, 0.001);
  color: red;
  /*&:before {
      @extend .icon--cross;
  }*/
}

.flash-message--info {
  background: rgba(0, 0, 255, 0.001);
  color: black;
  /*&:before {
      @extend .icon--info;
  }*/
}

.flash-message--warning {
  background: rgba(255, 165, 0, 0.001);
  color: black;
  /*&:before {
      @extend .icon--warning;
  }*/
}

.footer {
  background-color: var(--color-black);
  color: var(--color-gray);
  font-size: 16px;
  overflow: hidden;
}
.footer__top {
  padding: 60px 0;
}
@media only screen and (max-width: 500px) {
  .footer__top {
    padding: 40px 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .footer__top {
    padding: 40px 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .footer__top {
    padding: 40px 0;
  }
}
.footer__nav {
  background-color: var(--color-primary);
}
.footer__nav:before {
  content: "";
  background-color: var(--color-primary);
  position: absolute;
  right: -60px;
  top: -60px;
  left: -5000px;
  height: 355px;
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .footer__nav:before {
    height: 320px;
  }
}
@media only screen and (max-width: 500px) {
  .footer__nav:before {
    height: 300px;
  }
}
.footer__nav a {
  color: var(--color-white);
  transition: all 0.1s ease-in-out;
}
.footer__nav a:hover {
  padding-left: 3px;
}
.footer__nav:after {
  transition: all 0.2s ease-in-out;
  content: "";
  background-color: var(--color-black);
  position: absolute;
  transform: rotate(25deg);
  right: -126px;
  top: -52px;
  width: 200px;
  height: 600px;
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .footer__nav:after {
    transform: rotate(15deg);
    top: -60px;
    right: -140px;
  }
}
@media only screen and (max-width: 500px) {
  .footer__nav:after {
    transform: rotate(15deg);
    top: -52px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .footer__nav:after {
    transform: rotate(15deg);
    top: -52px;
  }
}
@media only screen and (max-width: 500px) {
  .footer__nav:after {
    right: -100px;
  }
}
@media only screen and (min-width: 1200px) {
  .footer__nav:hover:after {
    transform: rotate(0deg);
    top: -60px;
    right: -170px;
  }
}
@media only screen and (max-width: 500px) {
  .footer__nav {
    margin-bottom: 3rem;
  }
}
.footer__title {
  font-size: 20px;
  color: var(--color-white);
  font-family: var(--font-secondary);
  font-weight: var(--font-weight-medium);
  margin-bottom: 1.2rem;
  display: block;
}
.footer__contact {
  margin-bottom: -1rem;
}
.footer__contact a[href^=tel]:hover {
  color: var(--color-gray-light);
}
@media only screen and (max-width: 500px) {
  .footer__openinghours {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .footer__openinghours {
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 500px) {
  .footer__social-media {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .footer__social-media {
    margin-top: 2rem;
  }
}
.footer__social-media a {
  display: inline-block;
  margin-right: 7px;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .footer__social-media a {
    margin-left: 7px;
  }
}
.footer__social-media a:before {
  content: "";
  position: absolute;
  width: 90%;
  left: 5%;
  height: 90%;
  top: 5%;
  background-color: white;
  border-radius: 50%;
}
@media only screen and (min-width: 1081px) {
  .footer__social-media a:hover img {
    transform: scale(1.2) rotate(15deg);
  }
}
.footer__social-media img {
  margin: 0;
  transition: all 0.2s ease-in-out;
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .footer__social-media {
    position: absolute;
    bottom: 20px;
  }
}
.footer__logo {
  margin-top: 128px;
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .footer__logo {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .footer__logo {
    margin-top: 2rem;
    max-width: none;
    display: block;
  }
}
.footer__bottom {
  border-top: 1px solid var(--color-gray-dark);
  transition: all 0.2s ease-in-out;
}
@media only screen and (min-width: 1081px) {
  .footer__bottom:hover {
    border-top: 1px solid var(--color-gray2);
  }
}
.footer__bottom-nav {
  padding: 22px 0;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .footer__bottom-nav a {
    display: block;
    margin-top: 5px;
  }
}
.footer__bottom-nav a:before {
  content: "";
  border-left: 1px solid var(--color-gray2);
  width: 1px;
  margin: 0 10px 0 8px;
}
@media only screen and (max-width: 500px) {
  .footer__bottom-nav a:before {
    display: none;
  }
}
@media only screen and (min-width: 1081px) {
  .footer__bottom-nav a.-credits {
    position: absolute;
    right: 0;
  }
  .footer__bottom-nav a.-credits:before {
    display: none;
  }
}

.datepicker {
  display: none;
}
.datepicker.active {
  display: block;
}

.datepicker-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  padding-top: 4px;
}
.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}

.datepicker-picker {
  display: inline-block;
  border-radius: 4px;
  background-color: white;
}
.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 2px 3px rgba(45, 45, 45, 0.1), 0 0 0 1px rgba(45, 45, 45, 0.1);
}
.datepicker-picker span {
  display: block;
  flex: 1;
  border: 0;
  border-radius: 4px;
  cursor: default;
  text-align: center;
  -webkit-touch-callout: none;
  user-select: none;
}

.datepicker-main {
  padding: 2px;
}

.datepicker-footer {
  box-shadow: inset 0 1px 1px rgba(45, 45, 45, 0.1);
  background-color: #efefef;
}

.datepicker-grid, .datepicker-view .days-of-week, .datepicker-view, .datepicker-controls {
  display: flex;
}

.datepicker-grid {
  flex-wrap: wrap;
}

.datepicker-view .days .datepicker-cell, .datepicker-view .dow {
  flex-basis: 14.2857142857%;
}

.datepicker-view.datepicker-grid .datepicker-cell {
  flex-basis: 25%;
}

.datepicker-cell, .datepicker-view .week {
  height: 2.25em;
  line-height: 2.25em;
}

.datepicker-title {
  box-shadow: inset 0 -1px 1px rgba(45, 45, 45, 0.1);
  background-color: #efefef;
  padding: 0.375rem 0.75rem;
  text-align: center;
  font-weight: 700;
}

.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}
.datepicker-controls .button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  box-shadow: none;
  background-color: white;
  cursor: pointer;
  padding: calc(0.375em - 1px) 0.75em;
  height: 2.25em;
  vertical-align: top;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
  color: #3e3e3e;
  font-size: 1em;
}
.datepicker-controls .button:focus, .datepicker-controls .button:active {
  outline: none;
}
.datepicker-controls .button:hover {
  border-color: #646363;
  color: #3e3e3e;
}
.datepicker-controls .button:focus {
  border-color: #2ca536;
  color: #3e3e3e;
}
.datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(44, 165, 54, 0.25);
}
.datepicker-controls .button:active {
  border-color: #2d2d2d;
  color: #3e3e3e;
}
.datepicker-controls .button[disabled] {
  cursor: not-allowed;
}
.datepicker-header .datepicker-controls .button {
  border-color: transparent;
  font-weight: bold;
}
.datepicker-header .datepicker-controls .button:hover {
  background-color: #f9f9f9;
}
.datepicker-header .datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
}
.datepicker-header .datepicker-controls .button:active {
  background-color: #f2f2f2;
}
.datepicker-header .datepicker-controls .button[disabled] {
  box-shadow: none;
}
.datepicker-footer .datepicker-controls .button {
  margin: calc(0.375rem - 1px) 0.375rem;
  border-radius: 2px;
  width: 100%;
  font-size: 0.75em;
}
.datepicker-controls .view-switch {
  flex: auto;
}
.datepicker-controls .prev-btn,
.datepicker-controls .next-btn {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  width: 2.25em;
}
.datepicker-controls .prev-btn.disabled,
.datepicker-controls .next-btn.disabled {
  visibility: hidden;
}

.datepicker-view .dow {
  height: 1.5em;
  line-height: 1.5em;
  font-size: 0.875em;
  font-weight: 700;
}
.datepicker-view .week {
  width: 2.25em;
  color: #646363;
  font-size: 0.75em;
}
@media (max-width: 22.5em) {
  .datepicker-view .week {
    width: 1.96875em;
  }
}

.datepicker-grid {
  width: 15.75em;
}
@media (max-width: 22.5em) {
  .calendar-weeks + .days .datepicker-grid {
    width: 13.78125em;
  }
}

.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.datepicker-cell.focused:not(.selected) {
  background-color: #e2e2e2;
}
.datepicker-cell.selected, .datepicker-cell.selected:hover {
  background-color: #2ca536;
  color: #fff;
  font-weight: 600;
}
.datepicker-cell.disabled {
  color: #c9c9c9;
}
.datepicker-cell.prev:not(.disabled), .datepicker-cell.next:not(.disabled) {
  color: hsl(0deg, 0%, 48%);
}
.datepicker-cell.prev.selected, .datepicker-cell.next.selected {
  color: #e6e6e6;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: #646363;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #5e5d5d;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e2e2e2;
}
.datepicker-cell.today:not(.selected) {
  background-color: hsl(171deg, 100%, 41%);
}
.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}
.datepicker-cell.today.focused:not(.selected) {
  background-color: #00c4a7;
}
.datepicker-cell.range-end:not(.selected), .datepicker-cell.range-start:not(.selected) {
  background-color: #646363;
  color: #fff;
}
.datepicker-cell.range-end.focused:not(.selected), .datepicker-cell.range-start.focused:not(.selected) {
  background-color: #5e5d5d;
}
.datepicker-cell.range-start {
  border-radius: 4px 0 0 4px;
}
.datepicker-cell.range-end {
  border-radius: 0 4px 4px 0;
}
.datepicker-cell.range {
  border-radius: 0;
  background-color: #c9c9c9;
}
.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #c3c3c3;
}
.datepicker-cell.range.disabled {
  color: #b0b0b0;
}
.datepicker-cell.range.focused {
  background-color: #bcbcbc;
}
.datepicker-view.datepicker-grid .datepicker-cell {
  height: 4.5em;
  line-height: 4.5em;
}

.datepicker-input.in-edit {
  border-color: #27912f;
}
.datepicker-input.in-edit:focus, .datepicker-input.in-edit:active {
  box-shadow: 0 0 0.25em 0.25em rgba(39, 145, 47, 0.2);
}

.datepicker-title {
  padding: 0.375em 0.75em;
}

.datepicker-cell.focused:not(.selected) {
  color: var(--color-white);
}

.prev-btn,
.next-btn {
  padding-right: 0.375em;
  padding-left: 0.375em;
}

.form__entry {
  position: relative;
  margin-bottom: 1em;
}
.form__entry.-max-width-30 {
  max-width: 30rem;
}
.form__entry .is-disabled,
.form__entry .is-disabled .form__field,
.form__entry .is-disabled .form__label {
  cursor: default;
  color: var(--color-black);
}
.form__entry .is-disabled::-webkit-input-placeholder,
.form__entry .is-disabled .form__field::-webkit-input-placeholder,
.form__entry .is-disabled .form__label::-webkit-input-placeholder {
  color: var(--color-gray);
}
.form__entry .is-disabled:-moz-placeholder,
.form__entry .is-disabled .form__field:-moz-placeholder,
.form__entry .is-disabled .form__label:-moz-placeholder {
  color: var(--color-gray);
}
.form__entry .is-disabled::-moz-placeholder,
.form__entry .is-disabled .form__field::-moz-placeholder,
.form__entry .is-disabled .form__label::-moz-placeholder {
  color: var(--color-gray);
}
.form__entry .is-disabled:-ms-input-placeholder,
.form__entry .is-disabled .form__field:-ms-input-placeholder,
.form__entry .is-disabled .form__label:-ms-input-placeholder {
  color: var(--color-gray);
}
.form__entry .is-disabled.placeholder,
.form__entry .is-disabled .form__field.placeholder,
.form__entry .is-disabled .form__label.placeholder {
  color: var(--color-gray);
}
.form__entry .is-disabled .form__label--checkbox:before, .form__entry .is-disabled .form__label--checkbox:after,
.form__entry .is-disabled .form__label--radio:before,
.form__entry .is-disabled .form__label--radio:after {
  background: var(--color-gray);
}

.form__field--date,
.form__field--email,
.form__field--file,
.form__field--number,
.form__field--password,
.form__field--search,
.form__field--tel,
.form__field--text,
.form__field--textarea,
.form__field--time {
  font-size: 1.6rem;
  line-height: normal;
  margin: 0;
  width: 100%;
  padding: 1rem;
}

.form__field--date,
.form__field--email,
.form__field--file,
.form__field--number,
.form__field--password,
.form__field--search,
.form__field--tel,
.form__field--text,
.form__field--textarea,
.form__field--time {
  -webkit-appearance: none !important;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  padding: 1.2rem;
  word-break: normal;
  border: 1px solid var(--color-gray-light3);
  background-color: var(--color-white);
  color: var(--color-black);
}
.form__field--date::-webkit-input-placeholder,
.form__field--email::-webkit-input-placeholder,
.form__field--file::-webkit-input-placeholder,
.form__field--number::-webkit-input-placeholder,
.form__field--password::-webkit-input-placeholder,
.form__field--search::-webkit-input-placeholder,
.form__field--tel::-webkit-input-placeholder,
.form__field--text::-webkit-input-placeholder,
.form__field--textarea::-webkit-input-placeholder,
.form__field--time::-webkit-input-placeholder {
  color: var(--color-gray);
}
.form__field--date:-moz-placeholder,
.form__field--email:-moz-placeholder,
.form__field--file:-moz-placeholder,
.form__field--number:-moz-placeholder,
.form__field--password:-moz-placeholder,
.form__field--search:-moz-placeholder,
.form__field--tel:-moz-placeholder,
.form__field--text:-moz-placeholder,
.form__field--textarea:-moz-placeholder,
.form__field--time:-moz-placeholder {
  color: var(--color-gray);
}
.form__field--date::-moz-placeholder,
.form__field--email::-moz-placeholder,
.form__field--file::-moz-placeholder,
.form__field--number::-moz-placeholder,
.form__field--password::-moz-placeholder,
.form__field--search::-moz-placeholder,
.form__field--tel::-moz-placeholder,
.form__field--text::-moz-placeholder,
.form__field--textarea::-moz-placeholder,
.form__field--time::-moz-placeholder {
  color: var(--color-gray);
}
.form__field--date:-ms-input-placeholder,
.form__field--email:-ms-input-placeholder,
.form__field--file:-ms-input-placeholder,
.form__field--number:-ms-input-placeholder,
.form__field--password:-ms-input-placeholder,
.form__field--search:-ms-input-placeholder,
.form__field--tel:-ms-input-placeholder,
.form__field--text:-ms-input-placeholder,
.form__field--textarea:-ms-input-placeholder,
.form__field--time:-ms-input-placeholder {
  color: var(--color-gray);
}
.placeholder.form__field--date,
.placeholder.form__field--email,
.placeholder.form__field--file,
.placeholder.form__field--number,
.placeholder.form__field--password,
.placeholder.form__field--search,
.placeholder.form__field--tel,
.placeholder.form__field--text,
.placeholder.form__field--textarea,
.placeholder.form__field--time {
  color: var(--color-gray);
}
.form__field--date:focus,
.form__field--email:focus,
.form__field--file:focus,
.form__field--number:focus,
.form__field--password:focus,
.form__field--search:focus,
.form__field--tel:focus,
.form__field--text:focus,
.form__field--textarea:focus,
.form__field--time:focus {
  background-color: var(--color-white);
  border-color: var(--color-gray);
  outline: none;
}
[aria-invalid].form__field--date,
[aria-invalid].form__field--email,
[aria-invalid].form__field--file,
[aria-invalid].form__field--number,
[aria-invalid].form__field--password,
[aria-invalid].form__field--search,
[aria-invalid].form__field--tel,
[aria-invalid].form__field--text,
[aria-invalid].form__field--textarea,
[aria-invalid].form__field--time {
  border-color: var(--color-red);
}
[aria-invalid].form__field--date::-webkit-input-placeholder,
[aria-invalid].form__field--email::-webkit-input-placeholder,
[aria-invalid].form__field--file::-webkit-input-placeholder,
[aria-invalid].form__field--number::-webkit-input-placeholder,
[aria-invalid].form__field--password::-webkit-input-placeholder,
[aria-invalid].form__field--search::-webkit-input-placeholder,
[aria-invalid].form__field--tel::-webkit-input-placeholder,
[aria-invalid].form__field--text::-webkit-input-placeholder,
[aria-invalid].form__field--textarea::-webkit-input-placeholder,
[aria-invalid].form__field--time::-webkit-input-placeholder {
  color: var(--color-white);
}
[aria-invalid].form__field--date:-moz-placeholder,
[aria-invalid].form__field--email:-moz-placeholder,
[aria-invalid].form__field--file:-moz-placeholder,
[aria-invalid].form__field--number:-moz-placeholder,
[aria-invalid].form__field--password:-moz-placeholder,
[aria-invalid].form__field--search:-moz-placeholder,
[aria-invalid].form__field--tel:-moz-placeholder,
[aria-invalid].form__field--text:-moz-placeholder,
[aria-invalid].form__field--textarea:-moz-placeholder,
[aria-invalid].form__field--time:-moz-placeholder {
  color: var(--color-white);
}
[aria-invalid].form__field--date::-moz-placeholder,
[aria-invalid].form__field--email::-moz-placeholder,
[aria-invalid].form__field--file::-moz-placeholder,
[aria-invalid].form__field--number::-moz-placeholder,
[aria-invalid].form__field--password::-moz-placeholder,
[aria-invalid].form__field--search::-moz-placeholder,
[aria-invalid].form__field--tel::-moz-placeholder,
[aria-invalid].form__field--text::-moz-placeholder,
[aria-invalid].form__field--textarea::-moz-placeholder,
[aria-invalid].form__field--time::-moz-placeholder {
  color: var(--color-white);
}
[aria-invalid].form__field--date:-ms-input-placeholder,
[aria-invalid].form__field--email:-ms-input-placeholder,
[aria-invalid].form__field--file:-ms-input-placeholder,
[aria-invalid].form__field--number:-ms-input-placeholder,
[aria-invalid].form__field--password:-ms-input-placeholder,
[aria-invalid].form__field--search:-ms-input-placeholder,
[aria-invalid].form__field--tel:-ms-input-placeholder,
[aria-invalid].form__field--text:-ms-input-placeholder,
[aria-invalid].form__field--textarea:-ms-input-placeholder,
[aria-invalid].form__field--time:-ms-input-placeholder {
  color: var(--color-white);
}
[aria-invalid].placeholder.form__field--date,
[aria-invalid].placeholder.form__field--email,
[aria-invalid].placeholder.form__field--file,
[aria-invalid].placeholder.form__field--number,
[aria-invalid].placeholder.form__field--password,
[aria-invalid].placeholder.form__field--search,
[aria-invalid].placeholder.form__field--tel,
[aria-invalid].placeholder.form__field--text,
[aria-invalid].placeholder.form__field--textarea,
[aria-invalid].placeholder.form__field--time {
  color: var(--color-white);
}

/* form__FIELD--CHECKBOX */
.form__field--checkbox {
  display: inline-block;
  margin-left: 3px;
  margin-right: 0.5em;
  position: absolute;
  width: auto;
}

/* form__FIELD--RADIO */
.form__field--radio {
  /* NATIVE STYLING */
  /*
  display: inline-block;
  width: auto;

  margin-left: 3px;
  margin-right: 0.5em;
  vertical-align: bottom;
  */
  /* Custom Styling in label */
  display: none;
}
.form__field--radio + label {
  position: relative;
  padding-left: 1.5em;
}
.form__field--radio + label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  position: absolute;
  left: 0;
  top: 1px;
  vertical-align: middle;
  box-shadow: 0 0 1px rgba(35, 37, 41, 0.15) inset, 0 1px 0 rgba(35, 37, 41, 0.075) inset;
  background-color: #dddee2;
  color: var(--color-white);
  text-align: center;
}
.form__field--radio:checked + label:before {
  background: #1a94ff;
}
.form__field--radio:checked + label:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  position: absolute;
  top: 5px;
  left: 4px;
  background: var(--color-white);
}
.form__field--radio.-full-width + label {
  color: var(--color-black);
  height: 4rem;
}
.form__field--radio.-full-width + label::before {
  width: 100%;
  height: 4rem;
  border-radius: 0;
  z-index: -1;
  background-color: var(--color-green2);
}
.form__field--radio.-full-width:checked + label {
  color: var(--color-white);
}
.form__field--radio.-full-width:checked + label::before {
  background-color: var(--color-green);
}
.form__field--radio.-full-width:checked + label::after {
  background-image: url("../../images/tick-white.svg");
  background-color: transparent;
  background-size: 1.3rem 1.1rem;
  width: 1.3rem;
  height: 1.1rem;
  left: auto;
  right: 1.3rem;
}
.form__field--radio.-full-width.-disabled + label {
  cursor: default;
  pointer-events: none;
  color: var(--color-gray2);
}
.form__field--radio.-full-width.-disabled + label::before {
  background-color: var(--color-gray-lighter);
}

.form__field.form__field--search-date {
  background: transparent;
  color: var(--color-white);
  border: 0;
  padding: 0;
}
.form__field.form__field--search-date:focus {
  background: transparent;
}
.form__field.form__field--search-date::-webkit-calendar-picker-indicator {
  background-image: none;
  margin-right: -2.6rem;
  z-index: 1;
}
.form__field.form__field--search-date::after {
  content: "";
  display: inline-block;
  margin-left: 0.8rem;
  background-image: url("../../images/select-arrow-white.svg");
  background-size: 1.4rem 0.8rem;
  width: 1.4rem;
  height: 0.8rem;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .form__field.form__field--search-date::after {
    position: relative;
    right: 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .form__field.form__field--search-date::after {
    position: relative;
    right: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .form__field.form__field--search-date::after {
    position: relative;
    right: 0;
  }
}

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.h-hidden {
  display: none !important;
  visibility: hidden;
}

.h-visible {
  display: block !important;
  visibility: visible;
}

@media only screen and (min-width: 1081px) {
  .h-v-large__hidden {
    display: none !important;
    visibility: hidden;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .h-v-medium__hidden {
    display: none !important;
    visibility: hidden;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-v-small__hidden {
    display: none !important;
    visibility: hidden;
  }
}
@media only screen and (max-width: 500px) {
  .h-v-mini__hidden {
    display: none !important;
    visibility: hidden;
  }
}
/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 * Allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
 */
.form__fieldset--radio-buttons > input, .h-visuallyhidden--focusable, .h-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.h-visuallyhidden--focusable:active, .h-visuallyhidden--focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* form__FIELDSET */
.form__fieldset {
  border: 0;
  margin: 0 0 0.5em;
  padding: 0;
}

.form__fieldset--radio-buttons {
  display: inline-flex;
}
.form__fieldset--radio-buttons > input + label {
  background-color: var(--color-gray-lighter);
  border: 1px solid var(--color-gray-light3);
  border-left-width: 0;
  border-right-width: 0;
  color: var(--color-gray);
  font-size: 1.6rem;
  padding: 1.5rem 2rem 1.7rem;
  cursor: pointer;
}
.form__fieldset--radio-buttons > input:first-of-type + label {
  border-left-width: 1px;
  border-radius: 5px 0 0 5px;
}
.form__fieldset--radio-buttons > input:last-of-type + label {
  border-right-width: 1px;
  border-radius: 0 5px 5px 0;
}
.form__fieldset--radio-buttons > input:checked + label {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
}

.form__input-button {
  display: flex;
  align-items: flex-start;
}
.form__input-button > .form__field--text {
  border-radius: 4px 0 0 4px;
  margin-right: -1px;
}
.form__input-button > .button--black {
  margin-right: 0;
  border-radius: 0 0.4rem 0.4rem 0;
  padding: 1.16rem;
}

.form__label--checkbox,
.form__label--radio, .form__label {
  display: inline-block;
  cursor: pointer;
  color: var(--color-gray2);
  font-size: 15px;
  line-height: 1.2;
  margin: 0 0 0.5em;
}
@media only screen and (max-width: 500px) {
  .form__label--checkbox,
  .form__label--radio, .form__label {
    display: block;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .form__label--checkbox,
  .form__label--radio, .form__label {
    display: block;
  }
}

.form__label--checkbox,
.form__label--radio {
  color: var(--color-black);
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: 0.4em;
  padding-left: 1.8em;
  padding-right: 1.5em;
  position: relative;
  vertical-align: bottom;
}
.form__label--checkbox.-aligned,
.form__label--radio.-aligned {
  display: flex;
  align-items: center;
}
.form__label--checkbox.-aligned > img,
.form__label--radio.-aligned > img {
  margin: 0 1em 0 0.5em;
  max-height: 3rem;
  height: 100%;
}
.form__label--checkbox.-aligned > img[alt=BCMC], .form__label--checkbox.-aligned > img[alt="ING HomePay"], .form__label--checkbox.-aligned > img[alt="KBC Online"], .form__label--checkbox.-aligned > img[alt="CBC Online"], .form__label--checkbox.-aligned > img[alt="Belfius Direct Net"],
.form__label--radio.-aligned > img[alt=BCMC],
.form__label--radio.-aligned > img[alt="ING HomePay"],
.form__label--radio.-aligned > img[alt="KBC Online"],
.form__label--radio.-aligned > img[alt="CBC Online"],
.form__label--radio.-aligned > img[alt="Belfius Direct Net"] {
  max-height: 4rem;
}
.form__label--checkbox.-aligned > img[alt=VISA],
.form__label--radio.-aligned > img[alt=VISA] {
  max-height: 2.5rem;
}
.form__label--checkbox.-aligned::before,
.form__label--radio.-aligned::before {
  top: auto;
}
.form__field--radio:checked + .form__label--checkbox.-aligned::after,
.form__field--radio:checked + .form__label--radio.-aligned::after {
  top: auto;
}

.form__label--sublabel {
  display: block;
  color: var(--color-gray);
  font-size: 14px;
  padding-left: 2em;
  max-width: 37em;
}
.form__label > .form__label--sublabel {
  padding-left: 0;
  margin-top: 0.5em;
}

.form__legend {
  display: inline-block;
  cursor: pointer;
  color: var(--color-black);
  font-size: 16px;
  line-height: 1.25;
  margin: 0 0 0.5em 0;
}
@media only screen and (max-width: 500px) {
  .form__legend {
    display: block;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .form__legend {
    display: block;
  }
}

/* Form Message */
.form__message {
  display: block;
  color: var(--color-red);
  padding: 5px;
  margin-top: 0;
  font-size: 13px;
  line-height: 1;
  font-weight: 700;
}
.form__entry .form__message {
  display: none;
}
[aria-invalid] + .form__message, .form__message.-invalid {
  display: block;
}

.form__field--textarea + .form__message {
  margin-top: -10px;
}

.form__message .icon,
.form__message--icon:before {
  padding-right: 5px;
}

/* Variables */
/* Form Optional */
.form__optional {
  color: #888888;
  font-style: italic;
}

.form__row--1-2, .form__row--3-1, .form__row {
  display: grid;
  gap: 0 1em;
  align-items: baseline;
}

.form__row {
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
}

.form__row--3-1 {
  grid-template-columns: minmax(15em, 3fr) minmax(5em, 1fr);
}

.form__row--1-2 {
  grid-template-columns: minmax(5em, 1fr) minmax(15em, 2fr);
}

/* Form Select */
.form__select {
  display: block;
  font-size: 1.6rem;
  font-family: inherit;
  color: var(--color-black);
  line-height: normal;
  padding: 0.8em 1.4em 0.7em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid var(--color-gray-light3);
  box-shadow: 0;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-white);
  background-image: url("../../images/select-arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.form__select.-width-auto {
  width: auto;
}
.form__field.is-invalid .form__select {
  border-color: var(--color-red);
}
.form__select:focus {
  background-color: var(--color-white);
  border-color: var(--color-gray);
  outline: none;
}

.form__table {
  display: grid;
  gap: 2rem;
}
.form__table > div {
  display: grid;
  gap: 2rem;
  grid-template-columns: 3fr 1fr 4.5em;
  align-items: center;
}

.gallery__image {
  display: block;
  margin-bottom: 2rem;
}

/* ==========================================================================
   Variables
   ========================================================================== */
/* Colors */
/* Responsive true/false */
/* Default sizes */
/* Screen adaptive config */
/*
 *   This one needs some explanation:
 *
 *   It might look weird at first but it's actually pretty logical: to get a number without its unit, you need to divide
 *   it by 1 of the same unit. To get 42 from 42em, you need to divide 42em by 1em.
 *
 *   So we divide our number by the same number multiplied by 0 to which we then add 1.
 *   With our example, here is what happen: 42em / 42em * 0 + 1, so 42em / 0em + 1 so, 42em / 1em so 42.
 */
/**
 * You can use font-smoothing best when using light text on a dark background
 * This only works on Mac OS X
 *
 * More info:
 * - http://www.intridea.com/blog/2014/5/8/better-font-smoothing-in-chrome-on-mac-os-x
 * - http://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 */
/* MIXIN */
/* Make sure we have all parameters we gonna need */
/* Grid Container */
.grid__container, .grid--v-large__container, .grid__container--test {
  box-sizing: border-box;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

@media only screen and (min-width: 1081px) {
  .grid__container, .grid--v-large__container, .grid__container--test {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid__container, .grid--v-large__container, .grid__container--test {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .grid__container, .grid--v-large__container, .grid__container--test {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .grid__container, .grid--v-large__container, .grid__container--test {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .grid--v-large__container {
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .grid--v-large__container {
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-large__container {
    margin: 0;
    padding: 0;
  }
}
.grid__container--test [class*=__col] {
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border: 0 solid #ccc;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
}

.grid__container--test [class*=__col] [class*=__col] {
  margin-bottom: 0;
}

/* Grid Row */
.grid__row {
  width: 100%;
}

.grid__row:before, .grid__row:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.grid__row:after {
  clear: both;
}

/* Grid Col */
[class*=__col--],
[class*=__cols--] {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: left;
  width: 100%;
}

[class*=__col--].-omega,
[class*=__cols--].-omega {
  margin-right: 0 !important;
}

@media only screen and (min-width: 1081px) {
  /* Generate col & cols */
  /* Variables */
  .grid--v-large__col--1,
  .grid--v-large__cols--1 {
    width: 3.75%;
    margin-right: 5%;
  }
  .grid--v-large__cols--1:nth-child(12n) {
    margin-right: 0;
  }
  .grid--v-large__cols--1:nth-child(12n+1) {
    clear: left;
  }
  .-v-large__offset--1 {
    margin-left: 8.75%;
  }
  .grid--v-large__col--2,
  .grid--v-large__cols--2 {
    width: 12.5%;
    margin-right: 5%;
  }
  .grid--v-large__cols--2:nth-child(6n) {
    margin-right: 0;
  }
  .grid--v-large__cols--2:nth-child(6n+1) {
    clear: left;
  }
  .-v-large__offset--2 {
    margin-left: 17.5%;
  }
  .grid--v-large__col--3,
  .grid--v-large__cols--3 {
    width: 21.25%;
    margin-right: 5%;
  }
  .grid--v-large__cols--3:nth-child(4n) {
    margin-right: 0;
  }
  .grid--v-large__cols--3:nth-child(4n+1) {
    clear: left;
  }
  .-v-large__offset--3 {
    margin-left: 26.25%;
  }
  .grid--v-large__col--4,
  .grid--v-large__cols--4 {
    width: 30%;
    margin-right: 5%;
  }
  /* Subgrids */
  /* Variables */
  /* Omega class */
}
@media only screen and (min-width: 1081px) and (max-width: 500px) {
  .grid__row.-homepage .grid--v-large__col--4,
  .grid__row.-homepage .grid--v-large__cols--4 {
    width: 38.75%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) and (min-width: 501px) and (max-width: 820px) {
  .grid__row.-homepage .grid--v-large__col--4,
  .grid__row.-homepage .grid--v-large__cols--4 {
    width: 38.75%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) and (min-width: 821px) and (max-width: 1080px) {
  .grid__row.-homepage .grid--v-large__col--4,
  .grid__row.-homepage .grid--v-large__cols--4 {
    width: 38.75%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--4:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--4:nth-child(3n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 1081px) {
  .-v-large__offset--4 {
    margin-left: 35%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__col--5,
  .grid--v-large__cols--5 {
    width: 38.75%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--5:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--5:nth-child(2n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 1081px) {
  .-v-large__offset--5 {
    margin-left: 43.75%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__col--6,
  .grid--v-large__cols--6 {
    width: 47.5%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--6:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--6:nth-child(2n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 1081px) {
  .-v-large__offset--6 {
    margin-left: 52.5%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__col--7,
  .grid--v-large__cols--7 {
    width: 56.25%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) and (max-width: 500px) {
  .grid__row.-homepage .grid--v-large__col--7,
  .grid__row.-homepage .grid--v-large__cols--7 {
    width: 47.5%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) and (min-width: 501px) and (max-width: 820px) {
  .grid__row.-homepage .grid--v-large__col--7,
  .grid__row.-homepage .grid--v-large__cols--7 {
    width: 47.5%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) and (min-width: 821px) and (max-width: 1080px) {
  .grid__row.-homepage .grid--v-large__col--7,
  .grid__row.-homepage .grid--v-large__cols--7 {
    width: 47.5%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--7:nth-child(1n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--7:nth-child(1n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 1081px) {
  .-v-large__offset--7 {
    margin-left: 61.25%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__col--8,
  .grid--v-large__cols--8 {
    width: 65%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--8:nth-child(1n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--8:nth-child(1n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 1081px) {
  .-v-large__offset--8 {
    margin-left: 70%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__col--9,
  .grid--v-large__cols--9 {
    width: 73.75%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--9:nth-child(1n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--9:nth-child(1n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 1081px) {
  .-v-large__offset--9 {
    margin-left: 78.75%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__col--10,
  .grid--v-large__cols--10 {
    width: 82.5%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--10:nth-child(1n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--10:nth-child(1n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 1081px) {
  .-v-large__offset--10 {
    margin-left: 87.5%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__col--11,
  .grid--v-large__cols--11 {
    width: 91.25%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--11:nth-child(1n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--11:nth-child(1n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 1081px) {
  .-v-large__offset--11 {
    margin-left: 96.25%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__col--4-8,
  .grid--v-large__cols--4-8 {
    width: 46.15385%;
    margin-right: 7.69231%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--4-8:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--v-large__cols--4-8:nth-child(2n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 1081px) {
  .-v-large__omega {
    margin-right: 0 !important;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  /* Generate col & cols */
  /* Variables */
  .grid--v-medium__col--1,
  .grid--v-medium__cols--1 {
    width: 5.51282%;
    margin-right: 3.07692%;
  }
  .grid--v-medium__cols--1:nth-child(12n) {
    margin-right: 0;
  }
  .grid--v-medium__cols--1:nth-child(12n+1) {
    clear: left;
  }
  .-v-medium__offset--1 {
    margin-left: 8.58974%;
  }
  .grid--v-medium__col--2,
  .grid--v-medium__cols--2 {
    width: 14.10256%;
    margin-right: 3.07692%;
  }
  .grid--v-medium__cols--2:nth-child(6n) {
    margin-right: 0;
  }
  .grid--v-medium__cols--2:nth-child(6n+1) {
    clear: left;
  }
  .-v-medium__offset--2 {
    margin-left: 17.17949%;
  }
  .grid--v-medium__col--3,
  .grid--v-medium__cols--3 {
    width: 22.69231%;
    margin-right: 3.07692%;
  }
  .grid--v-medium__cols--3:nth-child(4n) {
    margin-right: 0;
  }
  .grid--v-medium__cols--3:nth-child(4n+1) {
    clear: left;
  }
  .-v-medium__offset--3 {
    margin-left: 25.76923%;
  }
  .grid--v-medium__col--4,
  .grid--v-medium__cols--4 {
    width: 31.28205%;
    margin-right: 3.07692%;
  }
  /* Subgrids */
  /* Omega class */
}
@media only screen and (min-width: 821px) and (max-width: 1080px) and (max-width: 500px) {
  .grid__row.-homepage .grid--v-medium__col--4,
  .grid__row.-homepage .grid--v-medium__cols--4 {
    width: 39.87179%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) and (min-width: 501px) and (max-width: 820px) {
  .grid__row.-homepage .grid--v-medium__col--4,
  .grid__row.-homepage .grid--v-medium__cols--4 {
    width: 39.87179%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) and (min-width: 821px) and (max-width: 1080px) {
  .grid__row.-homepage .grid--v-medium__col--4,
  .grid__row.-homepage .grid--v-medium__cols--4 {
    width: 39.87179%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--4:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--4:nth-child(3n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .-v-medium__offset--4 {
    margin-left: 34.35897%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__col--5,
  .grid--v-medium__cols--5 {
    width: 39.87179%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--5:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--5:nth-child(2n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .-v-medium__offset--5 {
    margin-left: 42.94872%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__col--6,
  .grid--v-medium__cols--6 {
    width: 48.46154%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--6:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--6:nth-child(2n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .-v-medium__offset--6 {
    margin-left: 51.53846%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__col--7,
  .grid--v-medium__cols--7 {
    width: 57.05128%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) and (max-width: 500px) {
  .grid__row.-homepage .grid--v-medium__col--7,
  .grid__row.-homepage .grid--v-medium__cols--7 {
    width: 48.46154%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) and (min-width: 501px) and (max-width: 820px) {
  .grid__row.-homepage .grid--v-medium__col--7,
  .grid__row.-homepage .grid--v-medium__cols--7 {
    width: 48.46154%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) and (min-width: 821px) and (max-width: 1080px) {
  .grid__row.-homepage .grid--v-medium__col--7,
  .grid__row.-homepage .grid--v-medium__cols--7 {
    width: 48.46154%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--7:nth-child(1n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--7:nth-child(1n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .-v-medium__offset--7 {
    margin-left: 60.12821%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__col--8,
  .grid--v-medium__cols--8 {
    width: 65.64103%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--8:nth-child(1n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--8:nth-child(1n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .-v-medium__offset--8 {
    margin-left: 68.71795%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__col--9,
  .grid--v-medium__cols--9 {
    width: 74.23077%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--9:nth-child(1n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--9:nth-child(1n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .-v-medium__offset--9 {
    margin-left: 77.30769%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__col--10,
  .grid--v-medium__cols--10 {
    width: 82.82051%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--10:nth-child(1n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--10:nth-child(1n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .-v-medium__offset--10 {
    margin-left: 85.89744%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__col--11,
  .grid--v-medium__cols--11 {
    width: 91.41026%;
    margin-right: 3.07692%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--11:nth-child(1n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .grid--v-medium__cols--11:nth-child(1n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .-v-medium__offset--11 {
    margin-left: 94.48718%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .-v-medium__omega {
    margin-right: 0 !important;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  /* Generate col & cols */
  /* Variables */
  .grid--v-small__col--1,
  .grid--v-small__cols--1 {
    width: 14.42308%;
    margin-right: 2.69231%;
  }
  .grid--v-small__cols--1:nth-child(6n) {
    margin-right: 0;
  }
  .grid--v-small__cols--1:nth-child(6n+1) {
    clear: left;
  }
  .-v-small__offset--1 {
    margin-left: 17.11538%;
  }
  .grid--v-small__col--2,
  .grid--v-small__cols--2 {
    width: 31.53846%;
    margin-right: 2.69231%;
  }
  .grid--v-small__cols--2:nth-child(3n) {
    margin-right: 0;
  }
  .grid--v-small__cols--2:nth-child(3n+1) {
    clear: left;
  }
  .-v-small__offset--2 {
    margin-left: 34.23077%;
  }
  .grid--v-small__col--3,
  .grid--v-small__cols--3 {
    width: 48.65385%;
    margin-right: 2.69231%;
  }
  .grid--v-small__cols--3:nth-child(2n) {
    margin-right: 0;
  }
  .grid--v-small__cols--3:nth-child(2n+1) {
    clear: left;
  }
  .-v-small__offset--3 {
    margin-left: 51.34615%;
  }
  .grid--v-small__col--4,
  .grid--v-small__cols--4 {
    width: 65.76923%;
    margin-right: 2.69231%;
  }
  .grid--v-small__cols--4:nth-child(1n) {
    margin-right: 0;
  }
  .grid--v-small__cols--4:nth-child(1n+1) {
    clear: left;
  }
  .-v-small__offset--4 {
    margin-left: 68.46154%;
  }
  .grid--v-small__col--5,
  .grid--v-small__cols--5 {
    width: 82.88462%;
    margin-right: 2.69231%;
  }
  .grid--v-small__cols--5:nth-child(1n) {
    margin-right: 0;
  }
  .grid--v-small__cols--5:nth-child(1n+1) {
    clear: left;
  }
  .-v-small__offset--5 {
    margin-left: 85.57692%;
  }
  /* Subgrids */
  /* Variables */
  .grid--v-small__col--1-2,
  .grid--v-small__cols--1-2 {
    width: 36.79245%;
    margin-right: 26.41509%;
  }
  .grid--v-small__cols--1-2:nth-child(2n) {
    margin-right: 0;
  }
  .grid--v-small__cols--1-2:nth-child(2n+1) {
    clear: left;
  }
  /* Omega class */
  .-v-small__omega {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 500px) {
  /* Generate col & cols */
  /* Variables */
  .grid--v-mini__col--1,
  .grid--v-mini__cols--1 {
    width: 49.23077%;
    margin-right: 1.53846%;
  }
  .grid--v-mini__cols--1:nth-child(2n) {
    margin-right: 0;
  }
  .grid--v-mini__cols--1:nth-child(2n+1) {
    clear: left;
  }
  .-v-mini__offset--1 {
    margin-left: 50.76923%;
  }
  /* Subgrids */
  /* Omega class */
  .-v-mini__omega {
    margin-right: 0 !important;
  }
}
.image-box > a {
  display: block;
}
.image-box__content {
  background-color: var(--color-gray-lighter);
  padding: 2rem;
}
.image-box__content > .button {
  margin-top: 2.5rem;
}

.info-box {
  background-color: var(--color-yellow);
  color: var(--color-brown);
  padding: 0.5em 1em 0.8em 2.2em;
  position: relative;
  font-size: 1.4rem;
  margin-bottom: 0.8em;
}
.info-box::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  mask-image: url("../../images/info.svg");
  background-color: var(--color-orange);
  position: absolute;
  top: 0.7em;
  left: 0.5em;
}
.info-box a {
  color: currentColor;
  text-decoration: underline;
}
.info-box a:hover, .info-box a:focus, .info-box a:active {
  text-decoration: none;
}

:root {
  --track-color: var(--color-black);
  --track-color-light: var(--color-gray-dark);
  --thumb-color: var(--color-primary);
  --thumb-radius: 14px;
  --thumb-height: 28px;
  --thumb-width: 28px;
  --track-width: 100%;
  --track-height: 4px;
  --track-radius: 5px;
}

[type=range] {
  -webkit-appearance: none;
  background: transparent;
  margin: calc(var(--thumb-height) / 2) 0;
  width: var(--track-width);
}
[type=range]::-moz-focus-outer {
  border: 0;
}
[type=range]:focus {
  outline: 0;
}
[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--track-color-light);
}
[type=range]:focus::-ms-fill-lower {
  background: var(--track-color);
}
[type=range]:focus::-ms-fill-upper {
  background: var(--track-color-light);
}
[type=range]::-webkit-slider-runnable-track {
  cursor: default;
  height: var(--track-height);
  transition: all 0.2s ease;
  width: var(--track-width);
  background: var(--track-color);
  border-radius: var(--track-radius);
}
[type=range]::-webkit-slider-thumb {
  background: var(--thumb-color);
  border-radius: var(--thumb-radius);
  box-sizing: border-box;
  cursor: default;
  height: var(--thumb-height);
  width: var(--thumb-width);
  -webkit-appearance: none;
  margin-top: calc(var(--track-height) / 2 - var(--thumb-height) / 2);
}
[type=range]::-moz-range-track {
  cursor: default;
  height: var(--track-height);
  transition: all 0.2s ease;
  width: var(--track-width);
  background: var(--track-color);
  border-radius: var(--track-radius);
  height: calc(var(--track-height) / 2);
}
[type=range]::-moz-range-thumb {
  background: var(--thumb-color);
  border-radius: var(--thumb-radius);
  box-sizing: border-box;
  cursor: default;
  height: var(--thumb-height);
  width: var(--thumb-width);
}
[type=range]:disabled::-webkit-slider-thumb, [type=range]:disabled::-moz-range-thumb, [type=range]:disabled::-ms-thumb, [type=range]:disabled::-webkit-slider-runnable-track, [type=range]:disabled::-ms-fill-lower, [type=range]:disabled::-ms-fill-upper {
  cursor: not-allowed;
}

.glightbox-clean .gslide-title {
  color: var(--color-white);
  margin: 0;
  max-width: none;
  text-align: center;
}

.glightbox-clean .gslide-description {
  background-color: transparent;
}

.desc-top .gslide-image img, .desc-bottom .gslide-image img {
  border: 10px solid white;
  max-height: 90vh;
  width: auto;
}

ul.list--downloads li {
  font-size: 1.4rem;
}
ul.list--downloads li:before {
  background-image: url("../../images/paper-sheet.svg");
  width: 11px;
  height: 14px;
  left: -24px;
  top: 4px;
}
@media only screen and (max-width: 500px) {
  ul.list--downloads li:before {
    left: -21px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  ul.list--downloads li:before {
    left: -21px;
  }
}
ul.list--downloads li a {
  font-size: 1.6rem;
}

.location {
  padding-left: 2.3rem;
  position: relative;
  color: var(--color-gray);
}
.location::before {
  content: "";
  display: inline-block;
  width: 1.4rem;
  height: 1.7rem;
  background-image: url("../../images/location-marker.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0.2rem;
}

.logos {
  display: flex;
  align-items: center;
  margin-bottom: 6rem;
  justify-content: center;
}
@media only screen and (max-width: 500px) {
  .logos {
    margin-bottom: 3rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .logos {
    margin-bottom: 3rem;
  }
}
.logos > img {
  margin: 0 2rem;
  max-width: 27%;
}
@media only screen and (max-width: 500px) {
  .logos > img {
    margin: 0 1rem;
    max-width: 29%;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .logos > img {
    margin: 0 1rem;
    max-width: 29%;
  }
}

.masthead {
  overflow-x: hidden;
}
.masthead__inner {
  position: relative;
  padding-top: 6rem;
  min-height: 21rem;
  z-index: 5;
}
.masthead__logo {
  position: absolute;
  top: 25px;
  left: 0;
  width: 180px;
  height: 180px;
  transition: transform 0.2s ease-in-out;
}
@media only screen and (min-width: 1081px) {
  .masthead__logo:hover {
    transform: scale(1.01) rotate(15deg);
  }
}
@media only screen and (max-width: 500px) {
  .masthead__logo {
    width: 10rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .masthead__logo {
    width: 10rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .masthead__logo {
    width: 10rem;
  }
}
@media only screen and (min-width: 1081px) {
  .masthead__top-nav {
    position: absolute;
    right: 0;
    top: 0;
  }
  .masthead__top-nav:before, .masthead__top-nav:after {
    content: "";
    position: absolute;
    transition: all 0.2s ease-in-out;
  }
  .masthead__top-nav:before {
    transform: rotate(60deg);
    width: 96px;
    height: 100%;
    border-bottom: 2px solid var(--color-gray-light2);
    bottom: 27px;
    left: -47px;
    z-index: -1;
  }
  .masthead__top-nav:after {
    height: 2px;
    background-color: var(--color-gray-light2);
    width: 9999px;
    left: 0;
    bottom: 0;
  }
  .masthead__top-nav:hover:before {
    border-bottom-color: var(--color-gray-light3);
  }
  .masthead__top-nav:hover:after {
    background-color: var(--color-gray-light3);
  }
  .masthead__top-nav a {
    display: inline-block;
    color: var(--color-gray2);
    font-weight: var(--font-weight-regular);
    margin-left: 2rem;
    padding: 16px 0;
  }
  .masthead__top-nav a:first-child {
    margin-left: 1rem;
  }
  .masthead__main-nav {
    text-align: right;
    margin: 38px 0;
    padding-left: 180px;
  }
}
@media only screen and (min-width: 1081px) and (max-width: 1350px) {
  .masthead__main-nav {
    padding-left: 100px;
  }
}
@media only screen and (min-width: 1081px) {
  .masthead__main-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .masthead__main-nav li {
    display: inline-block;
  }
}
@media only screen and (min-width: 1081px) {
  .masthead__main-nav a {
    font-family: var(--font-secondary);
    font-weight: var(--font-weight-bold);
    display: inline-block;
    text-transform: uppercase;
    margin-left: 2rem;
  }
  .masthead__main-nav a.-is-active {
    color: var(--color-gray2);
  }
}
@media only screen and (max-width: 500px) {
  .masthead__inner {
    min-height: 13.5rem;
  }
  .masthead__logo {
    left: 20px;
    top: 10px;
  }
  .masthead__menu-toggle {
    position: absolute;
    top: 25px;
    right: 30px;
    background-color: var(--color-gray-light2);
    font-family: var(--font-secondary);
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 0.5em 2.5em 0.5em 1em;
    font-weight: var(--font-weight-medium);
    border: 0;
    cursor: pointer;
    outline: none;
  }
  .masthead__menu-toggle:after {
    content: "";
    position: absolute;
    top: 0.9em;
    right: 1em;
    width: 1em;
    height: 0.125em;
    border-top: 0.375em double #000;
    border-bottom: 0.125em solid #000;
  }
  .masthead__menu {
    display: none;
  }
  .masthead__top-nav a, .masthead__main-nav a {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-secondary);
    font-size: 1.4rem;
    font-weight: var(--font-weight-bold);
    display: block;
    background-color: var(--color-black);
    color: white;
    text-align: center;
    padding: 1em 2em;
    border-top: 1px solid black;
    text-transform: uppercase;
  }
}
@media only screen and (max-width: 500px) and (-webkit-min-device-pixel-ratio: 1.25), only screen and (max-width: 500px) and (min-device-pixel-ratio: 1.25), only screen and (max-width: 500px) and (min-resolution: 200dpi), only screen and (max-width: 500px) and (min-resolution: 1.25dppx) {
  .masthead__top-nav a, .masthead__main-nav a {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
@media only screen and (max-width: 500px) {
  .masthead__top-nav a.-is-active, .masthead__main-nav a.-is-active {
    color: var(--color-primary);
  }
}
@media only screen and (max-width: 500px) {
  .masthead__top-nav {
    margin-top: 2.5rem;
  }
}
@media only screen and (max-width: 500px) {
  .masthead__main-nav ul {
    padding: 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .masthead__inner {
    min-height: 13.5rem;
  }
  .masthead__logo {
    left: 20px;
    top: 10px;
  }
  .masthead__menu-toggle {
    position: absolute;
    top: 25px;
    right: 30px;
    background-color: var(--color-gray-light2);
    font-family: var(--font-secondary);
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 0.5em 2.5em 0.5em 1em;
    font-weight: var(--font-weight-medium);
    border: 0;
    cursor: pointer;
    outline: none;
  }
  .masthead__menu-toggle:after {
    content: "";
    position: absolute;
    top: 0.9em;
    right: 1em;
    width: 1em;
    height: 0.125em;
    border-top: 0.375em double #000;
    border-bottom: 0.125em solid #000;
  }
  .masthead__menu {
    display: none;
  }
  .masthead__top-nav a, .masthead__main-nav a {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-secondary);
    font-size: 1.4rem;
    font-weight: var(--font-weight-bold);
    display: block;
    background-color: var(--color-black);
    color: white;
    text-align: center;
    padding: 1em 2em;
    border-top: 1px solid black;
    text-transform: uppercase;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-width: 501px) and (max-width: 820px) and (min-device-pixel-ratio: 1.25), only screen and (min-width: 501px) and (max-width: 820px) and (min-resolution: 200dpi), only screen and (min-width: 501px) and (max-width: 820px) and (min-resolution: 1.25dppx) {
  .masthead__top-nav a, .masthead__main-nav a {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .masthead__top-nav a.-is-active, .masthead__main-nav a.-is-active {
    color: var(--color-primary);
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .masthead__top-nav {
    margin-top: 2.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .masthead__main-nav ul {
    padding: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .masthead__inner {
    min-height: 13.5rem;
  }
  .masthead__logo {
    left: 20px;
    top: 10px;
  }
  .masthead__menu-toggle {
    position: absolute;
    top: 25px;
    right: 30px;
    background-color: var(--color-gray-light2);
    font-family: var(--font-secondary);
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 0.5em 2.5em 0.5em 1em;
    font-weight: var(--font-weight-medium);
    border: 0;
    cursor: pointer;
    outline: none;
  }
  .masthead__menu-toggle:after {
    content: "";
    position: absolute;
    top: 0.9em;
    right: 1em;
    width: 1em;
    height: 0.125em;
    border-top: 0.375em double #000;
    border-bottom: 0.125em solid #000;
  }
  .masthead__menu {
    display: none;
  }
  .masthead__top-nav a, .masthead__main-nav a {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-secondary);
    font-size: 1.4rem;
    font-weight: var(--font-weight-bold);
    display: block;
    background-color: var(--color-black);
    color: white;
    text-align: center;
    padding: 1em 2em;
    border-top: 1px solid black;
    text-transform: uppercase;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-width: 821px) and (max-width: 1080px) and (min-device-pixel-ratio: 1.25), only screen and (min-width: 821px) and (max-width: 1080px) and (min-resolution: 200dpi), only screen and (min-width: 821px) and (max-width: 1080px) and (min-resolution: 1.25dppx) {
  .masthead__top-nav a, .masthead__main-nav a {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .masthead__top-nav a.-is-active, .masthead__main-nav a.-is-active {
    color: var(--color-primary);
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .masthead__top-nav {
    margin-top: 2.5rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .masthead__main-nav ul {
    padding: 0;
  }
}

.no-script {
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid black;
  background-color: white;
  color: black;
  font-family: verdana;
  font-size: 11px;
  text-align: left;
  text-transform: uppercase;
}

.openinghours {
  line-height: 2;
}
@media only screen and (max-width: 500px) {
  .openinghours {
    width: 100%;
  }
}
.openinghours th, .openinghours td {
  vertical-align: top;
  text-align: left;
}
.openinghours th {
  width: 10rem;
  font-weight: var(--font-weight-regular);
}
.openinghours tr:hover {
  color: var(--color-gray-light);
}
.footer__top .openinghours {
  margin-top: -8px;
}

.pagination-select {
  position: relative;
}
.pagination-select__next {
  margin-left: 1em;
}
.pagination-select__next:after {
  content: ">";
  display: inline-block;
  margin-left: 5px;
  color: var(--color-black);
  font-size: 110%;
}
.pagination-select__prev {
  margin-right: 1em;
}
.pagination-select__prev:before {
  content: "<";
  display: inline-block;
  margin-right: 5px;
  color: var(--color-black);
  font-size: 110%;
}
.pagination-select__all {
  position: absolute;
  right: 0;
}

:root {
  --popup-bottom: 50%;
  --popup-max-width: 42em;
  --popup-transform: translate3d(0, calc(50% + .5px), 0);
  --popup-padding: 2em;
  --popup-background-color: white;
}

.popup {
  position: fixed;
  inset-block-end: var(--popup-bottom);
  transform: var(--popup-transform);
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  max-width: var(--popup-max-width);
  max-height: 100%;
  margin: auto auto 0;
  padding: 0;
  background-color: var(--popup-background-color);
  border: 0;
}
.popup p {
  margin-block-end: 2em;
}
.popup p:last-child {
  margin-block-end: 0;
}
.popup__inner {
  padding: var(--popup-padding);
}
.popup__image {
  display: block;
  width: 100%;
  margin: 0;
}
.popup__close {
  cursor: pointer;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  width: 4rem;
  height: 4rem;
  color: var(--color-black);
  text-transform: lowercase;
  background-color: var(--color-white);
  border: 0;
}
.popup__close::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: currentcolor;
  mask-image: url('data:image/svg+xml;utf8,<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 2rem;
}
.popup__close:hover, .popup__close:focus, .popup__close:active {
  background-color: var(--color-gray-light);
}
@media (max-width: 730px), (max-height: 500px) {
  .popup {
    --popup-bottom: 0;
    --popup-transform: none;
    --popup-max-width: none;
  }
}

.products {
  border-spacing: 0 1em;
  width: 100%;
}

.product {
  width: 100%;
}
.product__image {
  position: relative;
  width: 19rem;
  padding: 0;
  text-align: center;
}
.product__image a {
  display: block;
}
.product__image img {
  margin-block-start: 0;
}
.-bestseller .product__image {
  border: 2px solid var(--color-black);
  border-right: 0;
}
.product__image-promo {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  padding: 0.5rem 0.8rem;
  font-family: var(--font-secondary);
  font-size: 1.4rem;
  font-weight: var(--font-weight-bold);
  color: white;
  text-transform: uppercase;
  background-color: var(--color-red);
}
.product__image-promo.-bestseller {
  background-color: var(--color-black);
}
.product__details {
  padding: 1em 1.5em;
  background-color: var(--color-gray-light);
}
.-bestseller .product__details {
  border: 2px solid var(--color-black);
  border-left: 0;
}
.product__title {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  display: block;
  margin-block-end: 0.5em;
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--color-black);
  border-block-end: 1px solid var(--color-gray-light3);
}
.product__title > strong {
  margin-inline-start: 1rem;
  font-size: 110%;
  text-transform: uppercase;
}
.product__specs {
  width: 100%;
  font-size: 1.4rem;
}
.product__specs th {
  color: var(--color-gray2);
  text-align: start;
}
.product__specs th:last-child {
  width: 22em;
  text-align: end;
}
.product__specs th .button {
  margin-block: 0.25em 0;
}
.product__price-specs {
  padding-inline-end: 2rem;
  text-align: end;
}
.product__price {
  font-family: var(--font-secondary);
  font-size: 2.6rem;
  font-weight: var(--font-weight-bold);
  color: black;
  text-decoration: none;
}
.product__price.-promo {
  color: var(--color-red);
}
.product__old-price {
  margin-inline-end: 0.7rem;
  font-family: var(--font-secondary);
  font-size: 1.6rem;
  color: var(--color-gray);
  text-decoration: line-through;
}
.product__price-term {
  font-family: var(--font-secondary);
  font-size: 1.2rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}
.product__price-term.-promo {
  color: var(--color-red);
}
.product__price-vat-message {
  display: block;
  margin-block-start: -0.5rem;
  font-family: var(--font-secondary);
  font-size: 1.1rem;
  color: var(--color-gray);
}

#___ratingbadge_0 {
  left: 0;
}

.search-bar__container {
  width: 100%;
}
.title-bar--homepage + .search-bar__container {
  margin: 0 auto 6rem;
}
@media only screen and (max-width: 500px) {
  .title-bar--homepage + .search-bar__container {
    margin: 0 auto 3rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .title-bar--homepage + .search-bar__container {
    margin: 0 auto 3rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .title-bar--homepage + .search-bar__container {
    margin: 0 auto 3rem;
  }
}
@media only screen and (max-width: 500px) {
  .search-bar__container .grid__container {
    padding: 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .search-bar__container .grid__container {
    padding: 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .search-bar__container .grid__container {
    padding: 0;
  }
}

.search-bar__form {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 500px) {
  .search-bar__form {
    flex-direction: column;
    padding: 1.8rem 0 3.2rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .search-bar__form {
    flex-direction: column;
    padding: 1.8rem 0 3.2rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .search-bar__form {
    flex-direction: column;
    padding: 1.8rem 0 3.2rem;
  }
}
.search-bar__form::after {
  content: "";
  position: absolute;
  z-index: -1;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
}
@media only screen and (min-width: 1081px) {
  .search-bar__form::after {
    width: 200%;
  }
}
.search-bar__form > :nth-child(n) {
  display: inline-block;
  padding: 0 1.4rem;
  border-inline-end: 0.1rem solid var(--color-gray2);
}
@media only screen and (max-width: 500px) {
  .search-bar__form > :nth-child(n) {
    padding: 0 1.8rem 2rem;
    border-inline-end: none;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .search-bar__form > :nth-child(n) {
    padding: 0 1.8rem 2rem;
    border-inline-end: none;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .search-bar__form > :nth-child(n) {
    padding: 0 1.8rem 2rem;
    border-inline-end: none;
  }
}
.search-bar__form > :last-child {
  padding-inline-end: 0;
  border-inline-end: none;
}
@media only screen and (max-width: 500px) {
  .search-bar__form > :last-child {
    padding: 0 1.8rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .search-bar__form > :last-child {
    padding: 0 1.8rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .search-bar__form > :last-child {
    padding: 0 1.8rem;
  }
}
.search-bar__form .button {
  margin: 0.5rem 0 0;
}
.search-bar__form .form__entry {
  margin: 0;
}
@media only screen and (max-width: 500px) {
  .search-bar__form .form__entry {
    width: 100%;
    padding-inline-end: 1.8rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .search-bar__form .form__entry {
    width: 100%;
    padding-inline-end: 1.8rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .search-bar__form .form__entry {
    width: 100%;
    padding-inline-end: 1.8rem;
  }
}
.search-bar__form .form__entry:last-of-type {
  padding-inline-end: 2.8rem;
}
@media only screen and (min-width: 1350px) {
  .search-bar__form .form__entry {
    padding-inline-end: 6rem;
  }
}
.search-bar__form .form__label {
  font-family: var(--font-secondary);
  font-size: 1.3rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
  text-transform: uppercase;
  letter-spacing: 0.03rem;
}
@media only screen and (max-width: 500px) {
  .search-bar__form .form__label {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .search-bar__form .form__label {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .search-bar__form .form__label {
    font-size: 1.2rem;
  }
}
.search-bar__form .form__select {
  padding: 0.3rem 3rem 0.4rem 0;
  font-size: 1.6rem;
  color: var(--color-white);
  background-color: transparent;
  background-image: url("../../images/select-arrow-white.svg");
  border: none;
  border-radius: 0;
  appearance: none;
}
@media only screen and (max-width: 500px) {
  .search-bar__form .form__select {
    padding: 1.2rem 1.3rem 1.3rem;
    background-color: var(--color-gray3);
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .search-bar__form .form__select {
    padding: 1.2rem 1.3rem 1.3rem;
    background-color: var(--color-gray3);
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .search-bar__form .form__select {
    padding: 1.2rem 1.3rem 1.3rem;
    background-color: var(--color-gray3);
  }
}
.search-bar__form .form__select > option {
  color: var(--color-gray2);
  background-color: var(--color-white);
}
@media only screen and (max-width: 500px) {
  .search-bar__form .form__field--search-date {
    padding: 1.2rem 1.3rem 1.3rem;
    background-color: var(--color-gray3);
  }
  .search-bar__form .form__field--search-date:focus {
    background-color: var(--color-gray3);
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .search-bar__form .form__field--search-date {
    padding: 1.2rem 1.3rem 1.3rem;
    background-color: var(--color-gray3);
  }
  .search-bar__form .form__field--search-date:focus {
    background-color: var(--color-gray3);
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .search-bar__form .form__field--search-date {
    padding: 1.2rem 1.3rem 1.3rem;
    background-color: var(--color-gray3);
  }
  .search-bar__form .form__field--search-date:focus {
    background-color: var(--color-gray3);
  }
}
@media only screen and (min-width: 1081px) {
  .search-bar__form {
    padding-inline-start: 5rem;
  }
}
@media only screen and (min-width: 1350px) {
  .search-bar__form {
    padding-inline-start: 2.5rem;
  }
}

.step-layout {
  display: grid;
  gap: 2rem;
  grid-template-columns: auto 370px;
  max-width: 1105px;
  margin: 0 auto 4em;
}
@media only screen and (max-width: 500px) {
  .step-layout {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .step-layout {
    grid-template-columns: 1fr;
  }
}
.step-layout__price-summary {
  position: sticky;
  top: 0;
}

.steps {
  counter-reset: steps-counter;
  padding: 0;
}
.steps__step {
  text-align: center;
}
.steps__step:hover .steps__icon::after {
  transform: scale(1.4) rotate(5deg);
  color: var(--color-primary);
}
.steps__step:hover .steps__icon img {
  transform: scale(1.2);
}
.steps__step:hover .steps__title {
  color: var(--color-primary);
}
.steps__step:hover .steps__title::after {
  max-width: 140px;
}
.steps__icon {
  position: relative;
  display: inline-block;
  width: 162px;
  height: 160px;
  margin-block-end: 2rem;
  text-align: center;
  background: url(../../images/step-holder.svg) no-repeat;
  background-size: 100%;
}
.steps__icon img {
  display: inline-block;
  margin-block-start: 36px;
  margin-inline-start: -5px;
  transition: all 0.2s ease-in-out;
}
.steps__icon::after {
  content: counter(steps-counter);
  counter-increment: steps-counter;
  position: absolute;
  inset-block-start: 122px;
  inset-inline-start: 0;
  width: 100%;
  font-family: var(--font-secondary);
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-gray);
  transition: all 0.2s ease-in-out;
}
.steps__icon.-search img {
  margin-block-start: 40px;
}
.steps__icon.-store img {
  margin-block-start: 40px;
  margin-inline-start: 2px;
}
.steps__title::after {
  transition: max-width 0.2s ease-in-out;
}

.title-bar {
  text-align: center;
  background-color: var(--color-black);
  position: relative;
  margin-bottom: 4rem;
  margin-top: -5rem;
}
@media only screen and (min-width: 1081px) {
  .title-bar {
    margin-top: -6rem;
  }
}
@media only screen and (max-width: 1350px) {
  .title-bar {
    text-align: left;
  }
}
@media only screen and (max-width: 500px) {
  .title-bar {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .title-bar {
    margin-bottom: 2rem;
  }
}
.title-bar__container {
  position: relative;
  padding: 43px 0;
  overflow: hidden;
}
@media only screen and (max-width: 1350px) {
  .title-bar__container {
    padding: 33px 0;
  }
}
@media only screen and (min-width: 1081px) and (max-width: 1350px) {
  .title-bar .grid__container {
    padding: 0 4%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .title-bar .grid__container {
    padding: 0 11% 0 160px;
  }
}
@media only screen and (max-width: 500px) {
  .title-bar .grid__container {
    padding: 0 2em;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .title-bar .grid__container {
    padding: 0 2em;
  }
}
.title-bar__title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  color: var(--color-white);
  max-width: 700px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx) {
  .title-bar__title {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
.title-bar__title:before {
  content: "";
  width: 1000px;
  background-color: var(--color-green);
  border-top: 24px solid var(--color-white);
  border-bottom: 3000px solid var(--color-white);
  height: 32px;
  transform: rotate(120deg);
  position: absolute;
  left: -1750px;
  top: -1910px;
  z-index: 2;
}
@media only screen and (min-width: 1351px) {
  .title-bar__title:before {
    animation: move-line 0.7s ease forwards;
  }
}
@media only screen and (max-width: 1350px) {
  .title-bar__title:before {
    top: -2200px;
    height: 22px;
    border-top-width: 15px;
  }
}
@media only screen and (max-width: 500px) {
  .title-bar__title:before {
    display: none;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .title-bar__title:before {
    display: none;
  }
}
.title-bar__sub-title {
  color: var(--color-white);
  font-size: 2rem;
  margin-left: 20px;
}

@keyframes move-line {
  from {
    border-top-width: 0px;
    left: -1740px;
  }
  to {
    border-top-width: 24px;
    left: -1750px;
  }
}
.title-bar--homepage {
  position: relative;
  overflow: hidden;
  margin-top: -5rem;
}
@media only screen and (min-width: 1081px) {
  .title-bar--homepage {
    margin-top: -6rem;
    max-width: 1900px;
    margin-inline: auto;
  }
}
.title-bar--homepage .grid__container {
  padding: 0;
}
@media only screen and (min-width: 1081px) {
  .title-bar--homepage .grid__container {
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    position: unset;
  }
}
@media only screen and (min-width: 820px) {
  .title-bar--homepage .grid__container {
    height: 41.5rem;
  }
}
.title-bar--homepage .title-bar__container {
  height: 100%;
  z-index: 1;
  padding: 6.7rem 1.6rem 3.7rem;
}
@media only screen and (min-width: 820px) {
  .title-bar--homepage .title-bar__container {
    display: flex;
    align-items: center;
    max-width: 40%;
    position: relative;
    overflow: visible;
    padding: 7rem 1.6rem;
  }
  .title-bar--homepage .title-bar__container::after {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    background-color: var(--color-white);
    width: 175%;
    height: 100%;
    transform: skewX(-20deg);
    z-index: 1;
  }
}
@media only screen and (min-width: 1350px) {
  .title-bar--homepage .title-bar__container {
    max-width: 66rem;
    padding: 7rem 0 11rem;
  }
  .title-bar--homepage .title-bar__container::after {
    width: 155%;
  }
}
.title-bar--homepage .title-bar__title {
  color: var(--color-black);
  font-size: 2.8rem;
  font-weight: var(--font-weight-extra-bold);
  margin: 0 auto 0.8rem 0;
  z-index: 2;
}
@media only screen and (min-width: 500px) {
  .title-bar--homepage .title-bar__title {
    font-size: 3.5rem;
  }
}
@media only screen and (min-width: 1080px) {
  .title-bar--homepage .title-bar__title {
    font-size: 5rem;
  }
}
.title-bar--homepage .title-bar__title:before {
  display: none;
}
.title-bar--homepage .title-bar__title b {
  color: var(--color-primary);
}
.title-bar--homepage .title-bar__image {
  display: flex;
  align-items: center;
  max-height: 20rem;
  overflow: hidden;
}
@media only screen and (min-width: 500px) {
  .title-bar--homepage .title-bar__image {
    max-height: 30rem;
  }
}
@media only screen and (min-width: 820px) {
  .title-bar--homepage .title-bar__image {
    max-height: 46rem;
    position: absolute;
    top: 0;
    right: -20rem;
  }
}
@media only screen and (min-width: 1350px) {
  .title-bar--homepage .title-bar__image {
    right: 0;
  }
}
.title-bar--homepage .title-bar__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-icon--category:before {
  content: "";
  display: inline-block;
  background-image: url(../../images/category.svg);
  background-size: cover;
  height: 1em;
  margin-right: 0.5em;
  width: 1.2em;
}

.unit-block {
  background-color: var(--color-gray-lighter);
  margin-bottom: 1em;
  align-items: stretch;
  border: 2px solid transparent;
}
.unit-block.-bestseller {
  border-color: var(--color-black);
}
.unit-block .product__image-promo {
  inset-inline: auto 0;
}
@media (min-width: 700px) {
  .unit-block {
    display: flex;
    flex-flow: row-reverse;
  }
}
.unit-block img {
  margin-top: 0;
}
.unit-block__image {
  flex: 1 0 40%;
  position: relative;
}
.unit-block__image > img {
  margin-top: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .unit-block.-image-top {
    display: block;
  }
}
@media only screen and (min-width: 1081px) {
  .unit-block.-image-top {
    display: block;
  }
}
.unit-block__info {
  padding: 2rem;
}
.unit-block__title {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
}
@media (max-width: 499px) {
  .unit-block__title {
    display: block;
  }
}
.unit-block__price {
  color: var(--color-primary);
  font-size: 1.8rem;
  padding-top: 0.9rem;
  position: relative;
  top: -1em;
}
@media (min-width: 500px) {
  .unit-block__price {
    text-align: right;
  }
}
.unit-block__price > small {
  display: block;
}
.unit-block__action {
  color: var(--color-primary);
  font-size: 1.4rem;
}
.unit-block__action::after {
  content: "";
  display: inline-block;
  margin-left: 0.5em;
}
.unit-block__action.-edit::after {
  background-image: url("../../images/pensel.svg");
  width: 12px;
  height: 12px;
}

.unit-slider {
  position: relative;
  padding-top: 5rem;
}
.unit-slider > output {
  background-color: var(--color-black);
  border-radius: 3px;
  color: var(--color-white);
  cursor: default;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  margin-left: 14px;
  padding: 1rem;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}
.unit-slider > output::after {
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 0.6rem solid var(--color-black);
  border-right: 0.6rem solid transparent;
  border-bottom: 0;
  border-left: 0.6rem solid transparent;
  bottom: -0.6rem;
  content: "";
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.unit-slider__actions {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;
}

.video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
@media only screen and (min-width: 1081px) {
  .video {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .video {
    margin: 0 0 1em 0;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .video {
    margin: 0 0 1em 0;
  }
}
@media only screen and (max-width: 500px) {
  .video {
    margin: 0 0 1.5rem 0;
  }
}
.video.-home {
  padding-top: 0;
}
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.virtual-tour-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 23.7rem;
  position: relative;
  width: 100%;
}
.virtual-tour-link:hover > img, .virtual-tour-link:focus > img, .virtual-tour-link:active > img {
  opacity: 0.9;
}
.virtual-tour-link > .button {
  margin: 0;
}
.virtual-tour-link > svg {
  margin-bottom: 2em;
}
.virtual-tour-link > img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  transition: opacity 0.2s ease-in-out;
  margin-top: 0;
}
@media only screen and (min-width: 1080px) {
  .virtual-tour-link {
    height: 28.4rem;
    width: 41.3rem;
  }
}

.wrapper--gray {
  overflow: hidden;
}

.wrapper--gray {
  background-color: var(--color-gray-lighter);
}

.wrapper--flex {
  display: flex;
  flex-direction: column;
}

.wrapper--columns {
  display: flex;
  margin-top: 2rem;
}
@media only screen and (max-width: 500px) {
  .wrapper--columns {
    display: block;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .wrapper--columns {
    display: block;
  }
}
.wrapper--columns > * {
  width: 100%;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .wrapper--columns > * {
    width: calc(50% - 1.5rem);
    margin-right: 3rem;
  }
}
@media only screen and (min-width: 1081px) {
  .wrapper--columns > * {
    width: calc(50% - 1.5rem);
    margin-right: 3rem;
  }
}
.wrapper--columns > *:nth-child(2n) {
  margin-right: 0;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.h-clearfix:before, .h-clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
.h-clearfix:after {
  clear: both;
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .h-clearfix .h-float-left {
    float: left;
  }
  .h-clearfix .h-float-right {
    float: right;
  }
}
@media only screen and (min-width: 1081px) {
  .h-clearfix .h-float-left {
    float: left;
  }
  .h-clearfix .h-float-right {
    float: right;
  }
}

.h-clear {
  clear: both;
}

.h-margin-bottom--0 {
  margin-bottom: 0rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--0 {
    margin-bottom: 0rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--0 {
    margin-bottom: 0rem;
  }
}

.h-margin-bottom--1 {
  margin-bottom: 1rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--1 {
    margin-bottom: 0.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--1 {
    margin-bottom: 0.5rem;
  }
}

.h-margin-bottom--2 {
  margin-bottom: 2rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--2 {
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--2 {
    margin-bottom: 1rem;
  }
}

.h-margin-bottom--3 {
  margin-bottom: 3rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--3 {
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--3 {
    margin-bottom: 1.5rem;
  }
}

.h-margin-bottom--4 {
  margin-bottom: 4rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--4 {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--4 {
    margin-bottom: 2rem;
  }
}

.h-margin-bottom--5 {
  margin-bottom: 5rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--5 {
    margin-bottom: 2.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--5 {
    margin-bottom: 2.5rem;
  }
}

.h-margin-bottom--6 {
  margin-bottom: 6rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--6 {
    margin-bottom: 3rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--6 {
    margin-bottom: 3rem;
  }
}

.h-margin-bottom--7 {
  margin-bottom: 7rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--7 {
    margin-bottom: 3.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--7 {
    margin-bottom: 3.5rem;
  }
}

.h-margin-bottom--8 {
  margin-bottom: 8rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--8 {
    margin-bottom: 4rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--8 {
    margin-bottom: 4rem;
  }
}

.h-margin-bottom--9 {
  margin-bottom: 9rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--9 {
    margin-bottom: 4.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--9 {
    margin-bottom: 4.5rem;
  }
}

.h-margin-bottom--10 {
  margin-bottom: 10rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--10 {
    margin-bottom: 5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--10 {
    margin-bottom: 5rem;
  }
}

.h-margin-bottom--11 {
  margin-bottom: 11rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--11 {
    margin-bottom: 5.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--11 {
    margin-bottom: 5.5rem;
  }
}

.h-margin-bottom--12 {
  margin-bottom: 12rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--12 {
    margin-bottom: 6rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--12 {
    margin-bottom: 6rem;
  }
}

.h-margin-bottom--13 {
  margin-bottom: 13rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--13 {
    margin-bottom: 6.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--13 {
    margin-bottom: 6.5rem;
  }
}

.h-margin-bottom--14 {
  margin-bottom: 14rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--14 {
    margin-bottom: 7rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--14 {
    margin-bottom: 7rem;
  }
}

.h-margin-bottom--15 {
  margin-bottom: 15rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--15 {
    margin-bottom: 7.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--15 {
    margin-bottom: 7.5rem;
  }
}

.h-margin-bottom--16 {
  margin-bottom: 16rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--16 {
    margin-bottom: 8rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--16 {
    margin-bottom: 8rem;
  }
}

.h-margin-bottom--17 {
  margin-bottom: 17rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--17 {
    margin-bottom: 8.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--17 {
    margin-bottom: 8.5rem;
  }
}

.h-margin-bottom--18 {
  margin-bottom: 18rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--18 {
    margin-bottom: 9rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--18 {
    margin-bottom: 9rem;
  }
}

.h-margin-bottom--19 {
  margin-bottom: 19rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--19 {
    margin-bottom: 9.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--19 {
    margin-bottom: 9.5rem;
  }
}

.h-margin-bottom--20 {
  margin-bottom: 20rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--20 {
    margin-bottom: 10rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--20 {
    margin-bottom: 10rem;
  }
}

.h-margin-bottom--21 {
  margin-bottom: 21rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--21 {
    margin-bottom: 10.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--21 {
    margin-bottom: 10.5rem;
  }
}

.h-margin-bottom--22 {
  margin-bottom: 22rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--22 {
    margin-bottom: 11rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--22 {
    margin-bottom: 11rem;
  }
}

.h-margin-bottom--23 {
  margin-bottom: 23rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--23 {
    margin-bottom: 11.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--23 {
    margin-bottom: 11.5rem;
  }
}

.h-margin-bottom--24 {
  margin-bottom: 24rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--24 {
    margin-bottom: 12rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--24 {
    margin-bottom: 12rem;
  }
}

.h-margin-bottom--25 {
  margin-bottom: 25rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--25 {
    margin-bottom: 12.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--25 {
    margin-bottom: 12.5rem;
  }
}

.h-margin-bottom--26 {
  margin-bottom: 26rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--26 {
    margin-bottom: 13rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--26 {
    margin-bottom: 13rem;
  }
}

.h-margin-bottom--27 {
  margin-bottom: 27rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--27 {
    margin-bottom: 13.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--27 {
    margin-bottom: 13.5rem;
  }
}

.h-margin-bottom--28 {
  margin-bottom: 28rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--28 {
    margin-bottom: 14rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--28 {
    margin-bottom: 14rem;
  }
}

.h-margin-bottom--29 {
  margin-bottom: 29rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--29 {
    margin-bottom: 14.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--29 {
    margin-bottom: 14.5rem;
  }
}

.h-margin-bottom--30 {
  margin-bottom: 30rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--30 {
    margin-bottom: 15rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--30 {
    margin-bottom: 15rem;
  }
}

.h-margin-bottom--31 {
  margin-bottom: 31rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--31 {
    margin-bottom: 15.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--31 {
    margin-bottom: 15.5rem;
  }
}

.h-margin-bottom--32 {
  margin-bottom: 32rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--32 {
    margin-bottom: 16rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--32 {
    margin-bottom: 16rem;
  }
}

.h-margin-bottom--33 {
  margin-bottom: 33rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--33 {
    margin-bottom: 16.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--33 {
    margin-bottom: 16.5rem;
  }
}

.h-margin-bottom--34 {
  margin-bottom: 34rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--34 {
    margin-bottom: 17rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--34 {
    margin-bottom: 17rem;
  }
}

.h-margin-bottom--35 {
  margin-bottom: 35rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--35 {
    margin-bottom: 17.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--35 {
    margin-bottom: 17.5rem;
  }
}

.h-margin-bottom--36 {
  margin-bottom: 36rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--36 {
    margin-bottom: 18rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--36 {
    margin-bottom: 18rem;
  }
}

.h-margin-bottom--37 {
  margin-bottom: 37rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--37 {
    margin-bottom: 18.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--37 {
    margin-bottom: 18.5rem;
  }
}

.h-margin-bottom--38 {
  margin-bottom: 38rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--38 {
    margin-bottom: 19rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--38 {
    margin-bottom: 19rem;
  }
}

.h-margin-bottom--39 {
  margin-bottom: 39rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--39 {
    margin-bottom: 19.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--39 {
    margin-bottom: 19.5rem;
  }
}

.h-margin-bottom--40 {
  margin-bottom: 40rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-bottom--40 {
    margin-bottom: 20rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-bottom--40 {
    margin-bottom: 20rem;
  }
}

.h-margin-top--0 {
  margin-top: 0rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--0 {
    margin-top: 0rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--0 {
    margin-top: 0rem;
  }
}

.h-margin-top--1 {
  margin-top: 1rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--1 {
    margin-top: 0.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--1 {
    margin-top: 0.5rem;
  }
}

.h-margin-top--2 {
  margin-top: 2rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--2 {
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--2 {
    margin-top: 1rem;
  }
}

.h-margin-top--3 {
  margin-top: 3rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--3 {
    margin-top: 1.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--3 {
    margin-top: 1.5rem;
  }
}

.h-margin-top--4 {
  margin-top: 4rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--4 {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--4 {
    margin-top: 2rem;
  }
}

.h-margin-top--5 {
  margin-top: 5rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--5 {
    margin-top: 2.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--5 {
    margin-top: 2.5rem;
  }
}

.h-margin-top--6 {
  margin-top: 6rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--6 {
    margin-top: 3rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--6 {
    margin-top: 3rem;
  }
}

.h-margin-top--7 {
  margin-top: 7rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--7 {
    margin-top: 3.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--7 {
    margin-top: 3.5rem;
  }
}

.h-margin-top--8 {
  margin-top: 8rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--8 {
    margin-top: 4rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--8 {
    margin-top: 4rem;
  }
}

.h-margin-top--9 {
  margin-top: 9rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--9 {
    margin-top: 4.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--9 {
    margin-top: 4.5rem;
  }
}

.h-margin-top--10 {
  margin-top: 10rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--10 {
    margin-top: 5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--10 {
    margin-top: 5rem;
  }
}

.h-margin-top--11 {
  margin-top: 11rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--11 {
    margin-top: 5.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--11 {
    margin-top: 5.5rem;
  }
}

.h-margin-top--12 {
  margin-top: 12rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--12 {
    margin-top: 6rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--12 {
    margin-top: 6rem;
  }
}

.h-margin-top--13 {
  margin-top: 13rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--13 {
    margin-top: 6.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--13 {
    margin-top: 6.5rem;
  }
}

.h-margin-top--14 {
  margin-top: 14rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--14 {
    margin-top: 7rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--14 {
    margin-top: 7rem;
  }
}

.h-margin-top--15 {
  margin-top: 15rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--15 {
    margin-top: 7.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--15 {
    margin-top: 7.5rem;
  }
}

.h-margin-top--16 {
  margin-top: 16rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--16 {
    margin-top: 8rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--16 {
    margin-top: 8rem;
  }
}

.h-margin-top--17 {
  margin-top: 17rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--17 {
    margin-top: 8.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--17 {
    margin-top: 8.5rem;
  }
}

.h-margin-top--18 {
  margin-top: 18rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--18 {
    margin-top: 9rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--18 {
    margin-top: 9rem;
  }
}

.h-margin-top--19 {
  margin-top: 19rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--19 {
    margin-top: 9.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--19 {
    margin-top: 9.5rem;
  }
}

.h-margin-top--20 {
  margin-top: 20rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--20 {
    margin-top: 10rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--20 {
    margin-top: 10rem;
  }
}

.h-margin-top--21 {
  margin-top: 21rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--21 {
    margin-top: 10.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--21 {
    margin-top: 10.5rem;
  }
}

.h-margin-top--22 {
  margin-top: 22rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--22 {
    margin-top: 11rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--22 {
    margin-top: 11rem;
  }
}

.h-margin-top--23 {
  margin-top: 23rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--23 {
    margin-top: 11.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--23 {
    margin-top: 11.5rem;
  }
}

.h-margin-top--24 {
  margin-top: 24rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--24 {
    margin-top: 12rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--24 {
    margin-top: 12rem;
  }
}

.h-margin-top--25 {
  margin-top: 25rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--25 {
    margin-top: 12.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--25 {
    margin-top: 12.5rem;
  }
}

.h-margin-top--26 {
  margin-top: 26rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--26 {
    margin-top: 13rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--26 {
    margin-top: 13rem;
  }
}

.h-margin-top--27 {
  margin-top: 27rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--27 {
    margin-top: 13.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--27 {
    margin-top: 13.5rem;
  }
}

.h-margin-top--28 {
  margin-top: 28rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--28 {
    margin-top: 14rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--28 {
    margin-top: 14rem;
  }
}

.h-margin-top--29 {
  margin-top: 29rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--29 {
    margin-top: 14.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--29 {
    margin-top: 14.5rem;
  }
}

.h-margin-top--30 {
  margin-top: 30rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--30 {
    margin-top: 15rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--30 {
    margin-top: 15rem;
  }
}

.h-margin-top--31 {
  margin-top: 31rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--31 {
    margin-top: 15.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--31 {
    margin-top: 15.5rem;
  }
}

.h-margin-top--32 {
  margin-top: 32rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--32 {
    margin-top: 16rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--32 {
    margin-top: 16rem;
  }
}

.h-margin-top--33 {
  margin-top: 33rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--33 {
    margin-top: 16.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--33 {
    margin-top: 16.5rem;
  }
}

.h-margin-top--34 {
  margin-top: 34rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--34 {
    margin-top: 17rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--34 {
    margin-top: 17rem;
  }
}

.h-margin-top--35 {
  margin-top: 35rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--35 {
    margin-top: 17.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--35 {
    margin-top: 17.5rem;
  }
}

.h-margin-top--36 {
  margin-top: 36rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--36 {
    margin-top: 18rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--36 {
    margin-top: 18rem;
  }
}

.h-margin-top--37 {
  margin-top: 37rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--37 {
    margin-top: 18.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--37 {
    margin-top: 18.5rem;
  }
}

.h-margin-top--38 {
  margin-top: 38rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--38 {
    margin-top: 19rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--38 {
    margin-top: 19rem;
  }
}

.h-margin-top--39 {
  margin-top: 39rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--39 {
    margin-top: 19.5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--39 {
    margin-top: 19.5rem;
  }
}

.h-margin-top--40 {
  margin-top: 40rem;
}
@media only screen and (max-width: 500px) {
  .h-margin-top--40 {
    margin-top: 20rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-margin-top--40 {
    margin-top: 20rem;
  }
}

.h-margin-right--0 {
  margin-right: 0;
}

.h-margin-right--1 {
  margin-right: 1rem;
}

.h-margin-right--05 {
  margin-right: 0.5rem;
}

.h-sticky-footer__body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.h-sticky-footer__main {
  flex: 1 0 auto;
}

.h-sticky-footer__footer {
  flex-shrink: 0;
}

.h-text-align-right {
  text-align: right;
}

@media only screen and (min-width: 1081px) {
  .h-v-large__text-align-right {
    text-align: right;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1080px) {
  .h-v-medium__text-align-right {
    text-align: right;
  }
}
@media only screen and (min-width: 501px) and (max-width: 820px) {
  .h-v-small__text-align-right {
    text-align: right;
  }
}
@media only screen and (max-width: 500px) {
  .h-v-mini__text-align-right {
    text-align: right;
  }
}
.h-text-align-left {
  text-align: left;
}

.h-text-align-center {
  text-align: center;
}

.h-text-smaller {
  font-size: 1.4rem;
}

.container-inline-size {
  container-type: inline-size;
}