@use '../helpers/mixins';

.openinghours {
    line-height: 2;

    @include mixins.screen-size(mini) {
        width: 100%;
    }

    th, td {
        vertical-align: top;
        text-align: left;
    }

    th {
        width: 10rem;
        font-weight: var(--font-weight-regular);
    }

    tr:hover {
        color: var(--color-gray-light);
    }

    .footer__top & {
        margin-top: -8px;
    }
}
