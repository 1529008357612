@use '../../node_modules/@inventis/cookieconsent/src/cookie-consent.scss';

:root {
    --cookie-consent-options-background-color: var(--color-gray-lighter);
}

.cookie-consent {
    border-block-start: 2px solid var(--color-gray-light2);
    border-inline-start: 2px solid var(--color-gray-light2);
}

.cookie-consent__options {
    label {
        font-weight: bold;
    }

    input[type='checkbox'] {
        margin-inline-end: .5rem;
    }
}

.cookie-consent__option-description {
    font-weight: normal;
}
